<ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
  <li #menu [ngClass]="{ 'active-menuitem': isActive(i) }" *ngIf="child.visible !== false">
    <a
      *ngIf="!child['routerlink']"
      (click)="itemClick($event, child, i)"
      (mouseenter)="onMouseEnter(i)"
      pRipple
      [attr.tabindex]="!visible ? '-1' : null"
      [attr.target]="child.target"
      [href]="child.url || '#'">
      <ng-template *ngTemplateOutlet="childTemplate; context: { child }"> </ng-template>
    </a>

    <a
      *ngIf="child['routerlink']"
      (click)="itemClick($event, child, i)"
      (click)="checkItemTarget(child)"
      (mouseenter)="onMouseEnter(i)"
      pRipple
      [attr.tabindex]="!visible ? '-1' : null"
      [attr.target]="child.target"
      [routerLink]="child.target ? null : child['routerlink']"
      [routerLinkActive]="child.target ? '' : 'active-menuitem-routerlink'"
      [routerLinkActiveOptions]="{ exact: true }">
      <ng-template *ngTemplateOutlet="childTemplate; context: { child }"> </ng-template>
    </a>

    <!-- <div class="layout-menu-tooltip">
      <div class="layout-menu-tooltip-arrow"></div>
      <div class="layout-menu-tooltip-text">{{ child.label }}</div>
    </div> -->

    <ul
      prime-submenu
      *ngIf="child.items"
      [item]="child"
      [reset]="reset"
      [visible]="isActive(i)"
      [parentActive]="isActive(i)"
      [@children]="
        root &&
        (layoutService.config.menuMode === menuMode.HORIZONTAL || layoutService.config.menuMode === menuMode.SLIM)
          ? isActive(i)
            ? 'visible'
            : 'hidden'
          : isActive(i)
            ? 'visibleAnimated'
            : 'hiddenAnimated'
      "></ul>
  </li>
</ng-template>

<ng-template #childTemplate let-child="child">
  <i *ngIf="child.icon" class="material-icons" [textContent]="child.icon"> </i>
  <span [textContent]="child.label"></span>
  <i *ngIf="child.items" class="pi pi-chevron-down"> </i>
</ng-template>
