// Angular
import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
// Primeng
import { ToastModule } from 'primeng/toast';
// Caloudi
import { AppComponent } from '@app/app.component';
import { APP_ROUTES } from '@app/app.routing';
import * as Provider from '@app/provider.index';
import * as Formly from '@base/component/shared/caloudi-formly';
import { CommonErrorsHandler } from '@core/handler/common-errors-handler';
import { JwtInterceptor } from '@core/interceptor/jwt.interceptor';
// Store
import Effects from '@Effects';
import Reducers, { metaReducers, stateInit } from '@Reducers';
import { CustomSerializer } from '@store/custom-route-serializer';
import { CustomSanitizer } from '@store/custom-sanitizer';
// Interface
import environment from '@env';
// NGX
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { MarkdownModule } from 'ngx-markdown';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    TranslateModule.forRoot(),
    LoggerModule.forRoot({
      level: !environment.production ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.OFF,
      timestampFormat: "yyyy-MM-ddTHH:mm:ss.SSS'Z'Z",
      enableSourceMaps: true,
      serverLogLevel: NgxLoggerLevel.WARN,
      serverLoggingUrl: `${environment.apiServer.url}/api/ng/log`,
    }),
    StoreModule.forRoot(Reducers, {
      initialState: stateInit,
      metaReducers: metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
        strictActionTypeUniqueness: true,
      },
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
      navigationActionTiming: 2,
    }),
    EffectsModule.forRoot(Effects),
    FormlyModule.forRoot({
      types: [{ name: 'CustomMultiCheckbox', component: Formly.MultiCheckboxOtherComponent }],
      wrappers: [{ name: 'survey-form', component: Formly.SurveyFormWrapperComponent }],
      validationMessages: [{ name: 'required', message: 'This field is required.' }],
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 20,
      logOnly: environment.production,
      actionSanitizer: CustomSanitizer.ActionSanitizer,
      stateSanitizer: CustomSanitizer.StateSanitizer,
    }),
    RouterModule.forRoot(APP_ROUTES, {
      enableTracing: false, // !environment.production, // tracking route changes
      anchorScrolling: 'enabled', // scrolls to the anchor element when the URL has a fragment
      scrollOffset: [0, 64], // scroll offset when scrolling to an element (optional)
      scrollPositionRestoration: 'enabled', // restores the previous scroll position on backward navigation
    }),
    ToastModule,
    MarkdownModule,
    Provider.CUSTOM_MODULE,
  ],
  declarations: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: ErrorHandler, useClass: CommonErrorsHandler },
    { provide: DatePipe },
    Provider.CUSTOM_SERVICE,
    Provider.PRIME_NG_SERVICE,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
