// Angular
import { NgModule } from '@angular/core';
// Primeng
import { PrimengModule } from '@primeng';
// Caloudi
import { LayoutTabMenuModule } from '@layout-tabmenu/layout-tabmenu.module';
import { LayoutModule } from '@layout/layout.module';
import { LayoutWrapperComponent } from './layout-wrapper.component';

const FEATURE_COMPONENTS = [LayoutWrapperComponent];

@NgModule({
  imports: [
    PrimengModule,
    LayoutModule,
    LayoutTabMenuModule,
  ],
  declarations: [
    FEATURE_COMPONENTS,
  ],
})
export class LayoutWrapperModule { }
