// Anuglar
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewChecked, Component, ElementRef, HostBinding, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Event, NavigationEnd, RouterEvent } from '@angular/router';
// Rxjs
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
// Primeng
import { DomHandler } from 'primeng/dom';
// Caloudi
import { BaseComponent } from '@base';
import { MenuService } from '@base/service/layout';
import { MenuUtil } from '@util';
// Interface
import { MenuMode } from '@core/enum';
import { UserMenuItem } from '@core/model';

@Component({
  selector: '[layout-menuitem]',
  templateUrl: './layout-menuitem.component.html',
  styleUrls: ['./layout-menuitem.component.sass'],
  animations: [
    trigger('children', [
      state('collapsed', style({ height: '0' })),
      state('expanded', style({ height: '*' })),
      state('hidden', style({ opacity: 0, 'transform': 'translateY(1rem)' })),
      state('visible', style({ opacity: 1, 'transform': 'translateY(0)' })),
      // state('hidden', style({ display: 'none' })),
      // state('visible', style({ display: 'block' })),
      transition('collapsed <=> expanded', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hidden <=> visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
    ])
  ],
})
export class LayoutMenuItemComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewChecked {

  @Input('item') public item: UserMenuItem;
  @Input('index') public index: number;
  @Input('parentKey') public parentKey: string;
  @Input('root') public _root: boolean;
  @Input('menuRoot') public menuRoot: boolean;

  @HostBinding('class.layout-root-menuitem') public get root(): boolean {
    return this.isTabMenu ?
      (!this.layoutService.isMobile && (this.menuRoot || this.active))
      : this._root;
  }

  @HostBinding('class.active-menuitem') public get klassActiveItem(): boolean {
    return this.active;
  }

  @ViewChild('submenu') public submenu!: ElementRef<HTMLElement>;

  public get submenuAnimation(): 'collapsed' | 'expanded' | 'hidden' | 'visible' {
    if (this.isDesktop) return this.active ? 'visible' : 'hidden';
    else return this.root ? 'expanded' : (this.active ? 'expanded' : 'collapsed');
  }

  public get isTabMenu(): boolean { return this.layoutService.isTabMenu; }
  public get isDesktop(): boolean { return this.layoutService.isDesktop; }
  public get isHorizontal(): boolean { return this.layoutService.isHorizontal; }
  public get isSlim(): boolean { return this.layoutService.isSlim; }
  public get isSlimPlus(): boolean { return this.layoutService.isSlimPlus; }

  public readonly menuOrientation: typeof MenuMode = MenuMode;
  public readonly iconVer: typeof MenuUtil.iconVer = e => MenuUtil.iconVer(e);
  public readonly faIconConvert: typeof MenuUtil.faIconConvert = e => MenuUtil.faIconConvert(e);

  private readonly menuSourceSubscription: Subscription;
  private readonly menuResetSubscription: Subscription;

  public key: string = '';
  public active: boolean;

  private readonly logActive: boolean = !1;

  constructor(
    public readonly injector: Injector,
    private readonly menuService: MenuService,
  ) {
    super(injector);
    this.menuSourceSubscription = this.menuService.menuSource$.subscribe(value => {
      Promise.resolve(null).then((): void => {
        this.logActive && this.logger.debug('current:', [value]);
        if (value.routeEvent) {
          this.active = (value.key === this.key || value.key.startsWith(this.key + '-'));
        } else {
          if (value.key !== this.key && !value.key.startsWith(this.key + '-')) {
            this.active = false;
          }
        }
      });
    });

    this.menuResetSubscription = this.menuService.resetSource$.subscribe(() => {
      this.active = false;
    });

    this.router.events
      .pipe(filter((event: Event | RouterEvent) => event instanceof NavigationEnd && event.url !== '/reload'))
      .subscribe(_event => {
        if (typeof this.parentKey !== 'number') return;
        if (this.isSlimPlus || this.isSlim || this.isHorizontal || this.isTabMenu) this.active = false;
        else if (this.item.routerLink) this.updateActiveStateFromRoute();
      });
  }

  public ngOnInit(): void {
    if (!(this.isHorizontal || this.isSlim || this.isTabMenu) && this.item.routerLink)
      this.updateActiveStateFromRoute();
    this.key = this.parentKey ? this.parentKey + '-' + String(this.index) : String(this.index);
  }

  public ngAfterViewChecked(): void {
    if (this.root && this.active && this.isDesktop) {
      this.calculatePosition(this.submenu?.nativeElement, this.submenu?.nativeElement.parentElement);
    }
  }

  private calculatePosition(overlay: HTMLElement, target: HTMLElement): void {
    if (!overlay) return;
    const { left, top } = target.getBoundingClientRect();
    const [vWidth, vHeight] = [window.innerWidth, window.innerHeight];
    const [oWidth, oHeight] = [overlay.offsetWidth, overlay.offsetHeight];
    const scrollbarWidth = DomHandler.calculateScrollbarWidth();
    const topbarEl = document.querySelector<HTMLElement>('.layout-topbar');
    const topbarHeight = topbarEl?.offsetHeight || 0;
    // reset
    overlay.style.top = '';
    overlay.style.left = '';

    if (this.isHorizontal && !this.isTabMenu) {
      const width = left + oWidth + scrollbarWidth;
      overlay.style.left = vWidth < width ? `${left - (width - vWidth)}px` : `${left}px`;
    } else if (this.isSlim || this.isSlimPlus || this.isTabMenu) {
      const topOffset = top - topbarHeight;
      const height = topOffset + oHeight + topbarHeight;
      overlay.style.top = vHeight < height ? `${topOffset - (height - vHeight)}px` : `${topOffset}px`;
    }
  }

  private updateActiveStateFromRoute(): void {
    if (this.router.isActive(this.item.routerLink, {
      paths: 'exact',
      queryParams: this.item.items ? 'subset' : 'exact',
      matrixParams: 'ignored',
      fragment: 'ignored',
    })) this.menuService.onMenuStateChange({ key: this.key, routeEvent: true });
  }

  public itemClick(event: MouseEvent): void {
    // Avoid processing disabled items
    if (this.item.disabled) { event.preventDefault(); return; }

    // navigate with hover
    if (this.root && this.isSlim || this.isHorizontal || this.isSlimPlus || this.isTabMenu) {
      this.layoutService.state.menuHoverActive = !this.layoutService.state.menuHoverActive;
    }
    // execute command
    if (this.item.command) this.item.command({ originalEvent: event, item: this.item });

    // toggle active state
    if (this.item.items) {
      this.active = !this.active;

      if (this.root && this.active && (this.isSlim || this.isHorizontal || this.isSlimPlus || this.isTabMenu)) {
        this.layoutService.onOverlaySubmenuOpen();
      }
    } else {
      if (this.layoutService.isMobile) this.layoutService.state.staticMenuMobileActive = false;
      if (this.isSlim || this.isHorizontal || this.isSlimPlus || this.isTabMenu) {
        this.menuService.reset();
        this.layoutService.state.menuHoverActive = false;
      }
      if (this.item.target === '_blank' && this.item.routerLink) window.open(this.item.routerLink, '_blank');
    }

    this.menuService.onMenuStateChange({ key: this.key });

    this.logActive && this.logger.debug('item click:', [this.item, event, this.active, this.key, this.parentKey]);
  }

  public onMouseEnter(): void {
    // Activate item on hover
    if (this.root && (this.isHorizontal || this.isSlim || this.isTabMenu) && this.isDesktop) {
      if (this.layoutService.state.menuHoverActive) {
        this.menuService.onMenuStateChange({ key: this.key });
        this.active = true;
      }
    }
  }

  public ngOnDestroy(): void {
    this.menuSourceSubscription?.unsubscribe();
    this.menuResetSubscription?.unsubscribe();
  }
}
