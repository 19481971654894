<ng-container *ngFor="let item of menuItems">
  <a
    #menuAnchor
    class="topbar_text_menu"
    [ngClass]="{
      topbar_root_menuitem: root,
      inner_anchor: !root && item.items?.length > 0,
      'text-muted': !root && !item.routerLink && !item.items,
    }"
    [attr.pRipple]="!root && item.routerLink"
    (click)="!root && navigateTo(item.routerLink)"
    (mouseenter)="anchor(item.label, menuAnchor)"
    (mouseleave)="anchor(null)">
    <p class="menu_content" #menuContent>
      <span
        *ngIf="item.items?.length > 0"
        class="menu_content_indicator"
        [ngClass]="root ? 'order-3' : 'order-0'"
        [faIcon]="root ? fas.faAngleRight : fas.faAngleLeft"></span>
      <i *ngIf="iconVer(item.icon) === 'pi'" class="order-1" [ngClass]="item.icon"></i>
      <span
        *ngIf="iconVer(item.icon) === 'fas'"
        class="fa_icon order-1"
        [faIcon]="fas[faIconConvert(item.icon)]"></span>
      <span
        *ngIf="iconVer(item.icon) === 'fab'"
        class="fa_icon order-1"
        [faIcon]="fab[faIconConvert(item.icon)]"></span>
      <span class="menu_item_label order-2">{{ item.label }}</span>
    </p>

    <!-- Submenu -->
    <!-- <ng-container *ngIf="item.items?.length > 0 || (item.items?.length > 0 && mouseOver === item.label)"> -->
    <!-- <ng-container *ngIf="item.label === 'Sales Agent' || (item.items?.length > 0 && mouseOver === item.label)"> -->
    <!-- <ng-container *ngIf="item.label === 'Sys Admin' || (item.items?.length > 0 && mouseOver === item.label)"> -->
    <ng-container *ngIf="item.items?.length > 0 && mouseOver === item.label">
      <section
        menubar
        @subMenuAnimation
        class="menu_section"
        [style]="{ '--menu-width': menuWidth }"
        [items]="item.items"></section>
    </ng-container>
  </a>
</ng-container>
