/* eslint-disable @typescript-eslint/no-explicit-any */
// Angular
import { Injectable } from '@angular/core';
// Rxjs
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// Store
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TempStoreActionTypes, TempStorePayload } from '../actions';

@Injectable()
export class TempStoreEffects {
  constructor(private readonly actions$: Actions) {}

  public tempStore$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TempStoreActionTypes.Temp_Store),
        map(
          (action: TempStorePayload['Temp_Store']): any =>
            // console.log('effect', [action]);
            action
        ),
        catchError((err, cau) => {
          if (err) {
            console.error(err);
            return of(err);
          } else return cau;
        })
      ),
    { dispatch: true }
  );
}
