import { Route, Routes } from '@angular/router';

const aws: Routes = [
  {
    path: 'aws-usage',
    loadChildren: () => import('@aws-usage/aws-usage.module').then(m => m.AWSUsageModule),
  },
];

const azure: Routes = [
  {
    path: 'azure-usage',
    loadChildren: () => import('@azure-usage/azure-usage.module').then(m => m.AzureUsageModule),
  },
  {
    path: 'azure-ri',
    loadChildren: () => import('@azure-ri/azure-ri.module').then(m => m.AzureRIModule),
  },
  {
    path: 'azure-rm',
    loadChildren: () => import('@azure-rm/azure-rm.module').then(m => m.AzureRMModule),
  },
];

const xcloud: Routes = [
  {
    path: 'xcloud-usage',
    loadChildren: () => import('@xcloud-usage/xcloud-usage.module').then(m => m.XCloudUsageModule),
  },
];

const gcp: Routes = [
  {
    path: 'gcp-usage',
    loadChildren: () => import('@gcp-usage/gcp-usage.module').then(m => m.GCPUsageModule),
  },
  {
    path: 'gcp-ri',
    loadChildren: () => import('@gcp-ri/gcp-ri.module').then(m => m.GCPRIModule),
  },
  {
    path: 'gcp-rm',
    loadChildren: () => import('@gcp-rm/gcp-rm.module').then(m => m.GCPRMModule),
  },
];

const csp: Routes = [
  {
    path: 'csp-disti',
    loadChildren: () => import('@csp-disti/csp-disti.module').then(m => m.CspDistiModule),
  },
  {
    path: 'csp-partner',
    loadChildren: () => import('@csp-partner/csp-partner.module').then(m => m.CspPartnerModule),
  },
  {
    path: 'csp-reseller',
    loadChildren: () => import('@csp-reseller/csp-reseller.module').then(m => m.CspResellerModule),
  },
  {
    path: 'csp-customer',
    loadChildren: () => import('@csp-customer/csp-customer.module').then(m => m.CSPCustomerModule),
  },
];

const admins: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./admins/ea-admin/ea-admin.module').then(m => m.EAAdminModule),
  },
  {
    path: 'agent',
    loadChildren: () => import('./admins/agent-admin/agent-admin.module').then(m => m.AgentAdminModule),
  },
  {
    path: 'system-admin',
    loadChildren: () => import('./admins/sys-admin/sys-admin.module').then(m => m.SysAdminModule),
  },
  {
    path: 'user',
    loadChildren: () => import('@base/component/topbar-user/topbar-user.module').then(m => m.TopbarUserModule),
  },
];

const m365: Routes = [
  {
    path: 'microsoft-365-usage',
    loadChildren: () => import('./m365/m365-usage/m365-usage.module').then(m => m.M365UsageModule),
  },
];

const cloudsec: Routes = [
  {
    path: 'cloud-sec',
    loadChildren: () => import('./cloud-sec/cloud-sec.module').then(m => m.CloudsecModule),
  },
];

const vrm: Routes = [
  {
    path: 'vrm',
    loadChildren: () => import('./vrm/vrm.module').then(m => m.VRMModule),
  },
];

export const RoutesIndex: Route[] = [...aws, ...gcp, ...csp, ...azure, ...xcloud, ...m365, ...admins, ...cloudsec, ...vrm];
