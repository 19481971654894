// Angular
import { NgModule } from '@angular/core';
// Primeng
import { PrimengModule } from '@primeng';
// Caloudi
import * as Component from '.';

const FEATURE_COMPONENTS = [
  Component.IconmenuComponent,
  Component.LayoutMenuComponent,
  Component.LayoutMenuItemComponent,
  Component.LayoutSidebarComponent,
  Component.MenubarComponent,
  Component.PrimeConfigComponent,
];

@NgModule({
  imports: [PrimengModule],
  declarations: [FEATURE_COMPONENTS],
  exports: [FEATURE_COMPONENTS],
})
export class LayoutSharedModule { }
