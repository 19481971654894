<ng-container *ngIf="!item.unlisted && !item.visible">
  <ng-container *ngIf="!(isSlim || isSlimPlus || isHorizontal || isTabMenu)">
    <ul *ngIf="root">
      <li><ng-template [ngTemplateOutlet]="links"></ng-template></li>
    </ul>
    <ng-template *ngIf="!root" [ngTemplateOutlet]="links"></ng-template>
  </ng-container>

  <ng-container *ngIf="isSlim || isSlimPlus || isHorizontal || isTabMenu">
    <ng-template [ngTemplateOutlet]="links"></ng-template>
  </ng-container>
</ng-container>

<ng-template #links item>
  <a
    pRipple
    *ngIf="!item.routerLink || item.items"
    [attr.href]="item.url"
    (click)="itemClick($event)"
    (mouseenter)="onMouseEnter()"
    [ngClass]="item.class"
    [attr.target]="item.target"
    tabindex="0"
    [pTooltip]="item.label"
    [tooltipPosition]="isHorizontal ? 'bottom' : 'right'"
    [tooltipDisabled]="!root || active || !isSlim || !isTabMenu">
    <ng-template [ngTemplateOutlet]="content"></ng-template>
  </a>

  <a
    pRipple
    *ngIf="item.routerLink && !item.items"
    (click)="itemClick($event)"
    (mouseenter)="onMouseEnter()"
    [ngClass]="item.class"
    [routerLink]="item.routerLink"
    routerLinkActive="active-route"
    [routerLinkActiveOptions]="
      item.routerLinkActiveOptions || {
        paths: 'exact',
        queryParams: 'ignored',
        matrixParams: 'ignored',
        fragment: 'ignored',
      }
    "
    [fragment]="item.fragment"
    [queryParamsHandling]="item.queryParamsHandling"
    [preserveFragment]="item.preserveFragment"
    [skipLocationChange]="item.skipLocationChange"
    [replaceUrl]="item.replaceUrl"
    [state]="item.state"
    [queryParams]="item.queryParams"
    [attr.target]="item.target"
    tabindex="0"
    [pTooltip]="item.label"
    [tooltipPosition]="isHorizontal ? 'bottom' : 'right'"
    [tooltipDisabled]="!root || active || !isSlim || !isTabMenu">
    <ng-template [ngTemplateOutlet]="content"></ng-template>
  </a>

  <ul
    #submenu
    *ngIf="item.items"
    [@children]="submenuAnimation"
    class="submenu"
    [ngStyle]="{ display: isDesktop && active ? 'block' : 'none' }"
    [ngClass]="{ submenu_padding: isSlim || isSlimPlus || isTabMenu }">
    <li *ngIf="isSlim || isSlimPlus || isTabMenu" class="submenu_child">
      <p>{{ item.label }}</p>
    </li>
    <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
      <li
        layout-menuitem
        [item]="child"
        [index]="i"
        [parentKey]="key"
        [ngClass]="child.badgeClass"
        class="submenu_item"></li>
    </ng-template>
  </ul>
</ng-template>

<ng-template #content item>
  <i *ngIf="iconVer(item.icon) === 'pi'" class="menu_icon layout-menuitem-icon pi" [ngClass]="'pi-' + item.icon"></i>
  <fa-icon
    *ngIf="iconVer(item.icon) !== 'pi'"
    class="menu_icon fa_icon layout-menuitem-icon"
    [ngClass]="{ tabmenu_iconless: !item.icon }"
    [icon]="fas[faIconConvert(item.icon)] || fab[faIconConvert(item.icon)] || fas.faClose"></fa-icon>
  <!-- <span *ngIf="!item.icon" class="menu_icon fa_icon tabmenu_iconless" [faIcon]="fas.faClose"></span> -->
  <span class="layout-menuitem-text">{{ item.label }}</span>
  <fa-icon *ngIf="item.items" [icon]="fas.faChevronDown" class="layout-submenu-toggler"></fa-icon>
</ng-template>
