// Angular
import { Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
// Rxjs
import { filter, Subscription } from 'rxjs';
// Caloudi
import { BaseComponent } from '@base';
import { layoutAnimations } from '@base/component/layout-shared';
// Interface
import { ImgData } from '@base/model';
import { EAAPITYPE, MenuMode, ThemeTopbarColor } from '@core/enum';
import { Breadcrumb, UserMenuItem } from '@core/model';

@Component({
  selector: 'prime-topbar',
  templateUrl: './prime-topbar.component.html',
  styleUrls: ['./prime-topbar.component.sass'],
  animations: [layoutAnimations.topbarRightAnimation, layoutAnimations.breadcrumbAnimation],
})
export class PrimeTopbarComponent extends BaseComponent implements OnInit, OnDestroy {

  @ViewChild('menuButton') public menuButton!: ElementRef<HTMLElement>;
  @ViewChild('mobileMenuButton') public mobileMenuButton!: ElementRef<HTMLElement>;
  @ViewChild('searchInput') public searchInput!: ElementRef<HTMLElement>;

  public ThemeTopbarColor = ThemeTopbarColor;

  public readonly menubarStyle = {
    'background-color': 'transparent',
    border: 'unset',
  };

  public get isSlim(): boolean {
    return this.layoutService.config.menuMode === MenuMode.SLIM
      || this.layoutService.config.menuMode === MenuMode.SLIMPLUS;
  }

  public get cloudLogo(): boolean {
    return this.layoutService.config.menuMode === MenuMode.STATIC
      || this.layoutService.config.menuMode === MenuMode.OVERLAY;
  }

  /** View */
  public breadcrumb: Breadcrumb;
  public homeLink: string;
  public imgData: ImgData;

  public topbarMenuItems: UserMenuItem[];

  private top1MenuLabel: string;
  public get bindCompanyNameOnly(): UserMenuItem[] { return [{ label: this.top1MenuLabel }]; }

  private userMenuSub: Subscription;
  private authSub: Subscription;

  private readonly logActive: boolean = !1;

  constructor(
    public readonly injector: Injector,
    public readonly el: ElementRef<HTMLElement>,
  ) {
    super(injector);
    // this.logger.debug('D3 Version:', D3.version);
  }

  public ngOnInit(): void {
    this.userMenuSub = this.store
      .select(this.selectors.getUserMenu)
      .pipe(filter(m => !!m))
      .subscribe(userMenu => {
        this.topbarMenuItems = [];
        this.top1MenuLabel = userMenu.top1MenuLabel;
        [userMenu.top1Menu, userMenu.top2Menu, userMenu.top3Menu].forEach((menu, i): void => {
          if (!menu) return;
          this.topbarMenuItems = [
            ...this.topbarMenuItems,
            {
              label: [userMenu.top1MenuLabel, userMenu.top2MenuLabel, userMenu.top3MenuLabel][i],
              items: menu.map(item => ({
                label: item.label,
                icon: item.icon || '',
                routerLink: item['routerlink'] as string,
                items: item.items,
              })),
            }
          ];
        });
        this.logActive && this.logger.debug('userMenu:', [userMenu, this.topbarMenuItems]);
      });

    this.authSub = this.store
      .select(this.selectors.auth)
      .pipe(filter(auth => auth.loggedIn))
      .subscribe(auth => {
        this.breadcrumb = auth.breadcrumb;
        this.homeLink = auth.user.userMenu.homeRouterLink;

        switch (auth.user?.userProfile?.eaapiType) {
          case EAAPITYPE.AWS: this.imgData = this.layoutService.IconData().AWS; break;
          case EAAPITYPE.GCP: this.imgData = this.layoutService.IconData().GCP; break;
          case EAAPITYPE.XCloud: this.imgData = this.layoutService.IconData().XCLOUD; break;
          case EAAPITYPE.CloudSec: this.imgData = null; break;
          default: this.imgData = this.layoutService.IconData().AZURE;
        }
      });
  }

  public onMenuButtonClick(): void {
    this.layoutService.onMenuToggle();
  }

  public onMobileTopbarMenuButtonClick(): void {
    this.layoutService.onTopbarMenuToggle();
  }

  public isSVG(payload: ImgData): boolean {
    // this.logger.debug('isSVG:', [payload.path, RegExp(/\.svg$/i).test(payload.path)]);
    return RegExp(/\.svg$/i).test(payload.path);
  }

  public ngOnDestroy(): void {
    this.userMenuSub?.unsubscribe();
    this.authSub?.unsubscribe();
  }
}
