// Angular
import { Injectable } from '@angular/core';
// Rxjs
import { of } from 'rxjs';
import { catchError, map, withLatestFrom } from 'rxjs/operators';
// Store
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RouterNavigatedAction, ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { SetBreadcrumbAction } from '../actions';
import { AppState } from '../reducers';
import Selectors from '../selectors';
// Caloudi
import { MenuUtil } from '@util';

@Injectable()
export class RouterEffects {
  constructor(private readonly actions$: Actions, private readonly store: Store<AppState>) {}

  public navigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<RouterNavigatedAction>(ROUTER_NAVIGATED),
        withLatestFrom(this.store.select(Selectors.getBreadcrumbs)),
        map(([action, breadcrumbs]) => {
          const currentUrl = action.payload.routerState.url;
          // console.debug('navigated', [MenuUtil.getBreadcrumb(breadcrumbs, currentUrl), breadcrumbs, currentUrl]);
          if (!currentUrl.includes('/login')) {
            return SetBreadcrumbAction({ breadcrumb: MenuUtil.getBreadcrumb(breadcrumbs, currentUrl) });
          } else return SetBreadcrumbAction();
        }),
        catchError((err, cau) => {
          if (err) {
            console.error(err);
            return of(err);
          } else return cau;
        })
      ),
    { dispatch: true }
  );
}
