// Angular
import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
// Rxjs
import { Subject, takeUntil } from 'rxjs';
// Caloudi
import { BaseComponent } from '@base';
// Interface
import { UserMenu, UserMenuItem } from '@core/model';

@Component({
  selector: 'layout-menu, [layout-menu]',
  templateUrl: './layout-menu.component.html',
  styleUrls: ['./layout-menu.component.sass'],
  animations: [
    trigger('tabMenuLoadingAnimation', [
      transition(':enter', [
        style({ opacity: 0.5 }),
        animate('.4s cubic-bezier(0.86, 0, 0.07, 1)', style({ opacity: 1 })),
      ]),
    ]),
  ]
})
export class LayoutMenuComponent extends BaseComponent implements OnInit, OnDestroy {
  public model: UserMenuItem[];

  private readonly menuSub$ = new Subject<UserMenu>();

  private readonly logActive: boolean = !1;

  constructor(
    public readonly injector: Injector,
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    this.logActive && this.logger.debug('init');
    this.store
      .select(this.selectors.getUserMenu)
      .pipe(takeUntil(this.menuSub$))
      .subscribe(userMenu => {
        if (this.layoutService.isTabMenu || userMenu?.mainMenu.length === 0) return;
        this.model = this.rename(userMenu?.mainMenu);
        this.logActive && this.logger.debug('menu item:', [userMenu?.mainMenu, this.model, this.layoutService.config]);
      });
  }

  private rename(payload: UserMenuItem[]): UserMenuItem[] {
    if (!payload) return [];
    return payload.map<UserMenuItem>(item => {
      let result: UserMenuItem = { ...item, routerLink: item['routerlink'] as string };
      if (result.items) result.items = this.rename(item.items);
      if (!result.routerLink) delete result.routerLink;
      delete result['routerlink'];
      return result;
    });
  }

  public ngOnDestroy(): void {
    this.menuSub$?.unsubscribe();
  }
}
