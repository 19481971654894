<p-tabMenu
  [model]="layoutService.tabMenuItems"
  [activeItem]="layoutService.activeTab"
  [ngClass]="{ layout_dark: layoutService.config.colorScheme === colorScheme.DARK }"
  id="tab_menu">
  <ng-template pTemplate="item" let-item let-index="index">
    <div
      tabmenu-tabmenu-core-item
      [item]="item"
      [index]="index"
      [pTooltip]="item.label"
      [tooltipPosition]="'bottom'"
      [showDelay]="800"></div>
  </ng-template>
</p-tabMenu>
