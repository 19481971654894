// Primeng
import { PrimengModule } from '@primeng';
import { ConfirmationService, MessageService } from 'primeng/api';
// Caloudi
import { LocaleService } from '@base/service/translations';
import { CaloudiAppInsightsService } from '@core/service';
import { AppUtilModule, RouteUtilModule } from '@core/service/common';
import { UnauthurizedModule } from '@unauthurized/unauthurized.module';
// Layout
import { LayoutWrapperModule } from '@base/component/layout-wrapper/layout-wrapper.module';
import { LayoutService, MenuService } from '@base/service/layout';
import { LayoutTabMenuModule } from '@layout-tabmenu/layout-tabmenu.module';
import { LayoutModule } from '@layout/layout.module';
// Third Party
import { CustomFormlyModule } from '@base-shared/caloudi-formly/formly.module';
import { CookieService } from 'ngx-cookie-service';

export const CUSTOM_MODULE = [
  PrimengModule,
  LayoutModule,
  LayoutTabMenuModule,
  LayoutWrapperModule,
  UnauthurizedModule,
  CustomFormlyModule,
];

export const CUSTOM_SERVICE = [
  CaloudiAppInsightsService,
  MenuService,
  LayoutService,
  LocaleService,
  CookieService,
];

export const CUSTOM_UTIL = [
  RouteUtilModule,
  AppUtilModule,
];

export const PRIME_NG_SERVICE = [
  MessageService,
  ConfirmationService,
];
