// Angular
import { Component, Injector, OnDestroy } from '@angular/core';
// Rxjs
import { combineLatest, debounceTime, distinctUntilChanged, distinctUntilKeyChanged, first, fromEvent, merge, Subject, takeUntil } from 'rxjs';
// Caloudi
import { BaseComponent } from '@base';
import { JSONUtil } from '@util';
// Interface
import { LayoutType, ThemeOptions } from '@base/model';
import { ColorScheme } from '@core/enum';

@Component({
  selector: 'layout-wrapper',
  templateUrl: './layout-wrapper.component.html',
  styles: [],
})
export class LayoutWrapperComponent extends BaseComponent implements OnDestroy {
  private readonly logActive: boolean = !1;
  private readonly focusWatcher = new Subject<Event>();

  constructor(public readonly injector: Injector) {
    super(injector);

    /**
     * User Focus track
     */
    merge(
      fromEvent(window, 'focus').pipe(distinctUntilKeyChanged('timeStamp')),
      fromEvent(window, 'blur').pipe(distinctUntilKeyChanged('timeStamp'))
    )
      .pipe(takeUntil(this.focusWatcher))
      .subscribe(event => {
        this.logActive && this.logger.debug(`${event.type}:`, [event]);
      });

    /**
     * Check cookie alive & check layout type is xcloud or not on initial
     */
    combineLatest([
      this.store.select(this.selectors.getLayouts).pipe(first()),
      this.store.select(this.selectors.getEAAPIType).pipe(first()),
      this.store.select(this.selectors.getAppId).pipe(first()),
    ]).subscribe(([layout, eaapiType, appId]): void => {
      try {
        this.store.dispatch(this.actions.ChangeLayoutAction(JSONUtil.parse(this.cookie.get('caloudi_theme_layout'))));
      } catch (error) {
        this.logger.error('cookie theme error:', [error]);
      }
      if (!layout || !eaapiType || !appId) return;
      this.layoutService.checkLayoutType(layout, eaapiType, appId);
      this.layoutService.tabMenuListener();
      this.layoutService.layoutRouteListener();
      this.logActive && this.logger.debug('wrapper init:', [layout, eaapiType, appId, layout.layoutType as number >= 0]);
    });

    /**
     * Reflects layout config changes & store changes
     */
    this.store
      .select(this.selectors.getLayouts)
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe(layoutConfig => {
        this.logActive && this.logger.debug('layout change:', [{ ...this.layoutService.config }, { ...layoutConfig }]);
        const scale = ThemeOptions.scale.findIndex(e => e === layoutConfig.scale);
        this.layoutService.config = { ...layoutConfig };
        this.layoutService.selectedScale = scale >= 0 ? scale : 0;
        this.layoutService.storageLang = layoutConfig.lang;
        document.documentElement.style.fontSize = layoutConfig.scale + 'px';
        this.logActive && this.logger.debug('lo:', [layoutConfig, ThemeOptions]);

        if (typeof this.layoutService.config.layoutType === 'undefined')
          this.layoutService.config.layoutType = LayoutType.LEGACY;

        this.layoutService.tabMenuItems?.forEach(item => {
          const bORw = layoutConfig.colorScheme === ColorScheme.DARK;
          switch (item.id) {
            case 'UI_AWS': item.iconData = this.layoutService.IconData(bORw).AWS; break;
            case 'UI_Azure': item.iconData = this.layoutService.IconData(bORw).AZURE; break;
            case 'UI_GCP': item.iconData = this.layoutService.IconData(bORw).GCP; break;
            default: break;
          }
        });

        this.logActive && this.logger.debug('layout:', [{ ...this.layoutService.config }, { ...layoutConfig }]);

        const themeLink = <HTMLLinkElement>document.getElementById('theme-link');
        const themeHref = themeLink.getAttribute('href');
        const newTheme = themeHref
          .replace(/theme-(?:light|dark)/, `theme-${layoutConfig.colorScheme}`);
        const newHref = newTheme
          .replace(/(theme-(?:light|dark)\/)\w+(\/theme)/, `$1${layoutConfig.componentTheme}$2`);

        this.layoutService.replaceThemeLink(newHref, () => {
          this.layoutService.onConfigUpdate();
        });
      });
  }

  public ngOnDestroy(): void {
    this.focusWatcher?.next(null);
    this.focusWatcher?.unsubscribe();
  }
}
