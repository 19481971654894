// Angular
import { DOCUMENT } from '@angular/common';
import { Component, Inject, Injector, OnInit } from '@angular/core';
// Primeng
import { SelectItem } from 'primeng/api';
// Rxjs
import { first } from 'rxjs';
// Caloudi
import { BaseComponent } from '@base';
import { layoutAnimations } from '@base/component/layout-shared';
import { AnnouncementService, UserService } from '@base/service';
import environment from '@env';
// Interface
import { EAEnrollmentSelectItem, EATypeState } from '@base/model';
import { ComponentStateKey, DocumentLangType, EAAPITYPE, MessageSeverity } from '@core/enum';
import { UserMenuItem } from '@core/model';

@Component({
  selector: 'iconmenu, [iconmenu]',
  templateUrl: './iconmenu.component.html',
  styleUrls: ['./iconmenu.component.sass'],
  animations: [layoutAnimations.subMenuAnimation],
})
export class IconmenuComponent extends BaseComponent implements OnInit {
  public get CMP(): boolean {
    return this.appUtil.isCMP;
  }

  public readonly environment = environment;
  public readonly menuList = MenuList;

  public eaEnrollmentList: EAEnrollmentSelectItem[];
  public loginUserMenuItems: UserMenuItem[];

  public mouseOver: MenuList;

  public langOptions: SelectItem<DocumentLangType>[];
  public selectedLang: SelectItem<DocumentLangType>;

  public firstAnnouncement: string;
  public firstAnnouncementTime: string;

  private readonly state: EATypeState;

  public get envCheck(): boolean {
    return !environment.production && !RegExp(/^ysl-.*/i).test(environment.name);
  }

  constructor(
    public readonly injector: Injector,
    private readonly userService: UserService,
    private readonly announcementService: AnnouncementService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    super(injector);
    this.state = this.getState(ComponentStateKey.SwitchEnrollment, this.state);
  }

  public ngOnInit(): void {
    this.userService.getLanguageList().subscribe(langList => {
      this.langOptions = langList.map(
        lang => <SelectItem<DocumentLangType>>{ label: lang.languageName, value: lang.languageId }
      );
    });

    this.envCheck && this.announcementService.getFirstAnnouncementData().subscribe(res => {
      this.firstAnnouncement = res.title;
      this.firstAnnouncementTime = res.announcementTime?.slice(0, 10);
    });

    this.store.select(this.selectors.getUserMenu).subscribe(userMenu => {
      if (!userMenu) return;
      this.loginUserMenuItems = userMenu.loginUserMenu;
      this.eaEnrollmentList = userMenu.switchaleEnrollmentList;
    });
  }

  public onSwitchEAEnrollment(eaEnrollmentNumber: string): void {
    this.userService.switchEAEnrollment(eaEnrollmentNumber).subscribe(res => {
      this.logger.debug('switch enrollment:', [res]);
      // Update auth state
      this.store.dispatch(this.actions.UpdateUserAction({ authUser: res.currentUser }));

      // Remove component local state
      this.componentStateService.clearState();
      this.setState(ComponentStateKey.SwitchEnrollment, this.state || { apiType: res.currentUser });
      this.router.navigateByUrl(res.currentUser.userMenu.homeRouterLink);

      // location.assign(res.currentUser.userMenu.homeRouterLink);
      // this.router.navigateByUrl('/reload', { skipLocationChange: true })
      //   .then(() => this.router.navigateByUrl(res.currentUser.userMenu.homeRouterLink))
      //   .then(() => location.reload())
      //   .then(() => this.store.dispatch(Actions.GlobalMsgAction({
      //     severity: MessageSeverity.SUCCESS,
      //     summary: this.lang('ACTION.SWITCH_EA'),
      //     message: res.message,
      //   })));
    });
  }

  public onLangSelect(langLabel: DocumentLangType): void {
    this.logger.debug('lang selected:', [langLabel]);
    this.document.documentElement.lang = langLabel;
    this.userService.updateUserLang(langLabel).subscribe(res => {
      // Update auth state
      this.store.dispatch(this.actions.UpdateUserAction({ authUser: res.currentUser }));

      // Update lang state
      this.store.dispatch(this.actions.ChangeLanguageAction({ lang: langLabel }));

      // Refresh app
      this.store.dispatch(this.actions.RefreshPageAction());

      // Toast success message
      this.store.dispatch(
        this.actions.GlobalMsgAction({
          severity: MessageSeverity.SUCCESS,
          summary: this.lang('USER_PROFILE.SUMMARY'),
          message: res.message,
        })
      );
    });
  }

  public logout(): void {
    this.store.dispatch(
      this.actions.GlobalMsgAction({
        severity: MessageSeverity.INFO,
        summary: this.lang('PAGE.SIGN_OUT'),
      })
    );
    this.store.dispatch(this.actions.LogoutAction());
  }

  public documentRouterLink(): void {
    let eaAPI: string = '';
    let routeLink: string = '';
    // this.logger.debug('eaAPIType:', this.eaAPIType);
    this.store
      .select(this.selectors.getAuth)
      .pipe(first())
      .subscribe(res => {
        switch (res.user.userProfile.eaapiType) {
          case EAAPITYPE.CaloudiCSPClient:
            eaAPI = 'csp-customer';
            break;
          case EAAPITYPE.GCP:
            eaAPI = 'gcp-usage';
            break;
          case EAAPITYPE.AWS:
            eaAPI = 'aws-usage';
            break;
          case EAAPITYPE.XCloud:
            eaAPI = 'xcloud-usage';
            break;
          default:
            eaAPI = 'azure-usage';
            break;
        }
      });
    routeLink = `/pages/cmp/docs/${eaAPI}_index`;
    window.open(routeLink, '_blank');
  }

  public quickSet(): void {
    // this.logger.debug('quickSet', [this.userId]);
    this.router.navigate(['/pages/system-admin/cmp-user-admin'], {
      queryParams: { user: this.userId },
    });
  }
}

enum MenuList {
  SwitchEnrollment,
  ChangeLanguage,
  Profile,
  Announcement,
}
