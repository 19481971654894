import { Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { CommonUtil } from '@util';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
  frags: Record<string, unknown>;
}

export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  public serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    const [url, params, queryParams, frags] = [
      routerState.url,
      route.params,
      routerState.root.queryParams,
      CommonUtil.URLDecode(route.fragment) || {},
    ];

    // Only return an object including the URL, params and query params
    // Instead of the entire snapshot
    return { url, params, queryParams, frags } as RouterStateUrl;
  }
}
