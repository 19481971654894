// Angular
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
// Ngx
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
// Store
import { GlobalMsgAction } from '@Actions';
import { AppState } from '@Reducers';
// Caloudi
import { LanguageService } from '@core/service/config';
// Interface
import { MessageSeverity } from '@core/enum';
import { CommonError } from '@core/model';

@Injectable()
export class CommonErrorsHandler implements ErrorHandler {
  private logger: NGXLogger;
  private store: Store<AppState>;
  private lang: (key: string) => string;

  private readonly disableHttpErrors: boolean = true;
  // private readonly logActive: boolean;

  constructor(public readonly injector: Injector) {
    // Angular DI itself just doesn't support cyclic dependencies
    setTimeout(() => {
      this.logger = this.injector.get(NGXLogger);
      this.store = this.injector.get<Store<AppState>>(Store);
      this.lang = (key: string): string => {
        try { return this.injector.get(LanguageService).get(key).value; } catch (e) { return key; }
      };
    });
  }

  private errorToaster(error: CommonError, summary: string): void {
    // const errorFile = new RegExp(/at ([\w.]+)\s(\(.+\))/).exec(error.stack);
    this.store?.dispatch(
      GlobalMsgAction({
        severity: MessageSeverity.ERROR,
        summary,
        message: error instanceof HttpErrorResponse ? error.error?.message || error.message : error.message,
      })
    );
    this.logger?.error(
      `${error.name} (${new Date().toISOString()})`,
      // error.cause ? error : (error.stack, error),
      error
    );

    if (!this.logger || !this.store) console.error('It happens:', error);
  }

  public handleError(error: CommonError): void {
    if (error instanceof HttpErrorResponse) {
      if (this.disableHttpErrors) return;

      // Ignore login error. leave it to login component for error handling
      if (error.url.endsWith('api/auth/login')) return;

      // Ignore token expire error. leave it to jwt interceptor for error handling
      if (error.status === 401 && error.headers.has('www-authenticate')) return;

      // Custom error message from API
      this.errorToaster(error, this.lang('ERRROR_HANDLER.HTTP_ERROR_SUMMARY'));
    } else if (error instanceof EvalError) {
      this.errorToaster(error, 'Eval Error');
    } else if (error instanceof RangeError) {
      this.errorToaster(error, 'Range Error');
    } else if (error instanceof SyntaxError) {
      this.errorToaster(error, 'Syntax Error');
    } else if (error instanceof TypeError) {
      this.errorToaster(error, 'Type Error');
    } else if (error instanceof URIError) {
      this.errorToaster(error, 'URI Error');
    } else {
      this.errorToaster(error, 'Unknown Error');
    }
  }
}
