<!-- Switch user -->
<a
  *ngIf="eaEnrollmentList?.length > 0 && CMP"
  class="topbar_switch topbar_root_menuitem"
  (mouseover)="mouseOver = menuList.SwitchEnrollment"
  (mouseleave)="mouseOver = null">
  <fa-icon [icon]="fas.faUserGroup"></fa-icon>
  <section *ngIf="mouseOver === menuList.SwitchEnrollment" @subMenuAnimation id="switch_selection" class="menu_section">
    <div class="hgroup switch_enrollment_config">
      <h6>
        {{ 'ACTION.SWITCH_ENROLLMENT' | translate }}
        <fa-icon [icon]="fas.faCog" class="switch_enrollment_config_quickset" (click)="quickSet()"></fa-icon>
      </h6>
    </div>
    <hr />
    <div>
      <ng-container *ngFor="let eaEnrollment of eaEnrollmentList">
        <a
          pRipple
          (click)="onSwitchEAEnrollment(eaEnrollment.eaEnrollmentNumber)"
          [textContent]="eaEnrollment.eaName"></a>
      </ng-container>
    </div>
  </section>
</a>

<!-- Change languages -->
<a
  class="topbar_lang topbar_root_menuitem"
  (mouseover)="mouseOver = menuList.ChangeLanguage"
  (mouseleave)="mouseOver = null">
  <fa-icon [icon]="fas.faGlobe"></fa-icon>
  <section *ngIf="mouseOver === menuList.ChangeLanguage" @subMenuAnimation id="lang_selection" class="menu_section">
    <div class="hgroup">
      <h6>{{ 'LABEL.CHANGE_LANG' | translate }}</h6>
    </div>
    <hr />
    <div>
      <ng-container *ngFor="let lang of langOptions">
        <a pRipple (click)="onLangSelect(lang.value)" [textContent]="lang.label"></a>
      </ng-container>
    </div>
  </section>
</a>

<!-- User profile -->
<a
  class="topbar_profile topbar_root_menuitem"
  (mouseover)="mouseOver = menuList.Profile"
  (mouseleave)="mouseOver = null">
  <fa-icon [icon]="fas.faUser"></fa-icon>
  <section *ngIf="mouseOver === menuList.Profile" @subMenuAnimation id="user_profile" class="menu_section">
    <div class="hgroup">
      <ng-container *ngIf="layoutService.profile?.userId && layoutService.profile?.userName">
        <h6 [copy]="layoutService.profile.userId">{{ layoutService.profile.userId }}</h6>
        <h6>{{ layoutService.profile.userName }}</h6>
      </ng-container>
    </div>
    <hr />
    <div>
      <ng-container *ngFor="let loginUserMenuItem of loginUserMenuItems">
        <a pRipple [routerLink]="loginUserMenuItem['routerlink']" [textContent]="loginUserMenuItem.label"></a>
      </ng-container>
    </div>
    <hr />
    <div>
      <a id="logout_btn" pRipple (click)="logout()">
        <h6>{{ 'PAGE.SIGN_OUT' | translate }}</h6>
        <fa-icon [icon]="fas.faArrowRightFromBracket"></fa-icon>
      </a>
    </div>
  </section>
</a>

<!-- Document -->
<a *ngIf="envCheck" pRipple class="topbar_root_menuitem" (click)="documentRouterLink()">
  <fa-icon [icon]="fas.faBook"></fa-icon>
</a>

<!-- Announcement -->
<a
  *ngIf="envCheck && layoutService.hasReadAnnouncement"
  class="topbar_announcement topbar_root_menuitem"
  (mouseover)="mouseOver = menuList.Announcement"
  (mouseleave)="mouseOver = null">
  <div
    [attr.pBadge]="!layoutService.hasReadAnnouncement && firstAnnouncement"
    class="p-overlay-badge announcement_badge"
    severity="danger">
    <fa-icon [icon]="fas.faBell"></fa-icon>
  </div>
  <section
    *ngIf="mouseOver === menuList.Announcement"
    @subMenuAnimation
    id="announcement_selection"
    class="menu_section">
    <div class="hgroup">
      <h6>{{ 'LABEL.ANNOUNCEMENT' | translate }}</h6>
    </div>
    <hr />
    <div>
      <a
        *ngIf="firstAnnouncement"
        pRipple
        (click)="layoutService.hasReadAnnouncement = true"
        [routerLink]="'/pages/user/announcement'">
        <div class="announcement_container">
          <div class="announcement_content">
            <p class="announcement_time">{{ firstAnnouncementTime }}</p>
            <p class="announcement_title">{{ firstAnnouncement }}</p>
          </div>
        </div>
      </a>
      <p *ngIf="!firstAnnouncement" class="none_announcement">{{ 'PAGE.NONE' | translate }}</p>
    </div>
    <hr />
    <div>
      <a pRipple (click)="layoutService.hasReadAnnouncement = true" [routerLink]="'/pages/user/announcement'">
        <h6>{{ 'LABEL.MORE' | translate }}</h6>
      </a>
    </div>
  </section>
</a>
