// Angular
import { AfterViewInit, Component, ElementRef, HostBinding, Injector, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
// Rxjs
import { fromEvent } from 'rxjs';
// Caloudi
import { BaseComponent } from '@base';
import { layoutAnimations } from '@base/component/layout-shared';
import { MenuUtil } from '@util';
// Interface
import { ImgData, TabMenuItem } from '@base/model';
import { ColorScheme } from '@core/enum';

@Component({
  selector: '[tabmenu-tabmenu-core-item]',
  templateUrl: './tabmenu-core-item.component.html',
  styleUrls: ['./tabmenu-core-item.component.sass'],
  animations: [layoutAnimations.subMenuAnimation],
  encapsulation: ViewEncapsulation.None,
})
export class TabMenuTabMenuCoreItemComponent extends BaseComponent implements OnChanges, AfterViewInit {
  @HostBinding('class.tab_menu_item') public get klassItem(): boolean {
    return !0;
  }

  @HostBinding('class.tab_menu_dropdown') public get klassDropdown(): boolean {
    return this.tabMenuItem.items?.length > 0;
  }

  @HostBinding('class.layout_dark') public get klassDark(): boolean {
    return this.layoutService.config.colorScheme === this.ColorScheme.DARK;
  }

  @Input('item') public tabMenuItem: TabMenuItem;
  @Input('index') public index: number;

  public readonly iconVer: typeof MenuUtil.iconVer = e => MenuUtil.iconVer(e);
  public readonly faIconConvert: typeof MenuUtil.faIconConvert = e => MenuUtil.faIconConvert(e);

  public selectedDropdownTab: TabMenuItem;
  public displaySublist: boolean;
  public offsetX: string;
  public ColorScheme: typeof ColorScheme = ColorScheme;

  private readonly logActive: boolean;

  constructor(public readonly injector: Injector, private readonly el: ElementRef<HTMLElement>) {
    super(injector);
  }

  public ngAfterViewInit(): void {
    fromEvent(this.el.nativeElement.parentElement, 'click').subscribe((e: MouseEvent): void => {
      if (!this.tabMenuItem?.items) return;
      const pel = this.el.nativeElement.parentElement;

      this.offsetX = String(Math.floor(pel.getClientRects()[0].left)) + 'px';
      this.displaySublist = true;

      this.logActive && this.logger.debug('click:', [pel, pel.getClientRects(), pel.clientLeft]);
      this.logActive && this.logger.debug('click:', [this.tabMenuItem, e]);
    });

    fromEvent(this.el.nativeElement.parentElement, 'mouseleave').subscribe((e: MouseEvent): void => {
      if (!this.displaySublist) return;
      this.displaySublist = false;
      this.logActive && this.logger.debug('leave:', [e, this.el.nativeElement]);
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.logActive && this.logger.debug('changes:', [changes]);
  }

  public isSVG(payload: ImgData): boolean {
    this.logActive && this.logger.debug('isSVG:', [payload.path, RegExp(/\.svg$/i).test(payload.path)]);
    return RegExp(/\.svg$/i).test(payload.path);
  }

  public onSelectDropdownTab(tab: TabMenuItem): void {
    this.logActive && this.logger.debug('tab:', [this.el, tab]);
    this.router.navigate([tab.routerLink || tab.routerlink]);
  }

  public subListClicked(tab: TabMenuItem): void {
    if (!tab.routerLink && !tab.routerlink) return;
    this.router.navigate([tab.routerLink || tab.routerlink]);
    this.logActive && this.logger.debug('subListClicked:', [tab]);
  }
}
