// Angular
import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Injector, OnDestroy } from '@angular/core';
// Rxjs
import { Subscription } from 'rxjs';
// Caloudi
import { BaseComponent } from '@base';
import { layoutAnimations } from '@base/component/layout-shared';
// Interface
import { MenuMode, ThemeTopbarColor } from '@core/enum';
import { UserMenuItem } from '@core/model';

@Component({
  selector: 'tabmenu-topbar, [tabmenu-topbar]',
  templateUrl: './tabmenu-topbar.component.html',
  styleUrls: ['./tabmenu-topbar.component.sass'],
  animations: [
    trigger('tabAnimation', [
      transition(':enter', [
        style({ transform: 'translate(0, 50%)', opacity: 0, width: 0 }),
        animate('.4s cubic-bezier(0.86, 0, 0.07, 1)', style({ width: '*' })),
        animate('.4s cubic-bezier(0.86, 0, 0.07, 1)', style({ transform: 'translate(0, 0)', opacity: '*' })),
      ]),
    ]),
    layoutAnimations.breadcrumbAnimation,
    layoutAnimations.topbarRightAnimation,
  ],
})
export class TabMenuTopbarComponent extends BaseComponent implements OnDestroy {


  public readonly menubarStyle: { [key: string]: string; } = {
    'background-color': 'transparent',
    border: 'unset',
  };

  public readonly menuOptions: typeof MenuMode = MenuMode;
  public readonly ThemeTopbarColor: typeof ThemeTopbarColor = ThemeTopbarColor;

  public topbarMenuItems: UserMenuItem[];

  private readonly userMenuSub: Subscription;

  private readonly logActive: boolean = !1;

  constructor(
    public readonly injector: Injector,
    public readonly el: ElementRef<HTMLElement>,
  ) {
    super(injector);
    // this.logger.debug('D3 Version:', D3.version);

    this.userMenuSub = this.store.select(this.selectors.getUserMenu).subscribe(userMenu => {
      if (!userMenu) return;
      this.topbarMenuItems = [];
      [userMenu.top2Menu, userMenu.top3Menu].forEach((menu: UserMenuItem[], i: number): void => {
        if (!menu) return;
        this.topbarMenuItems = [
          ...this.topbarMenuItems,
          {
            label: [userMenu.top2MenuLabel, userMenu.top3MenuLabel][i],
            items: menu.map<UserMenuItem>(item => ({
              label: item.label,
              icon: item.icon || '',
              routerLink: item['routerlink'] as string,
              items: item.items,
            })),
          },
        ];
      });
      this.logActive && this.logger.debug('userMenu:', [userMenu, this.topbarMenuItems]);
    });
  }

  public ngOnDestroy(): void {
    this.userMenuSub?.unsubscribe();
  }
}
