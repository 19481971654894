// Angular
import { DOCUMENT } from '@angular/common';
import { Component, Inject, Injector, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { Event, NavigationEnd, RouterEvent } from '@angular/router';
// Rxjs
import { filter, Subscription } from 'rxjs';
// Caloudi
import { BaseComponent } from '@base';
import { layoutAnimations, LayoutSidebarComponent } from '@base/component/layout-shared';
import { MenuService } from '@base/service/layout';
import { PrimeTopbarComponent } from '@layout';
// Interface
import { LayoutType } from '@base/model';
import { ColorScheme, InputStyles, MenuMode } from '@core/enum';
import { ListenEvent } from '@core/model';

@Component({
  selector: 'prime-main',
  templateUrl: './prime-main.component.html',
  styleUrls: ['./prime-main.component.sass'],
  animations: [layoutAnimations.routeAnimation],
})
export class PrimeMainComponent extends BaseComponent implements OnDestroy {

  @ViewChild(PrimeTopbarComponent) private readonly appTopbar!: PrimeTopbarComponent;
  @ViewChild(LayoutSidebarComponent) private readonly appSidebar!: LayoutSidebarComponent;

  public get containerClass(): { [key: string]: boolean; } {
    let styleClass: { [key: string]: boolean; } = {
      'layout-overlay': this.layoutService.config.menuMode === MenuMode.OVERLAY,
      'layout-static': this.layoutService.config.menuMode === MenuMode.STATIC,
      'layout-slim': this.layoutService.config.menuMode === MenuMode.SLIM,
      'layout-slim-plus': this.layoutService.config.menuMode === MenuMode.SLIMPLUS,
      'layout-horizontal': this.layoutService.config.menuMode === MenuMode.HORIZONTAL,
      'layout-reveal': this.layoutService.config.menuMode === MenuMode.REVEAL,
      'layout-drawer': this.layoutService.config.menuMode === MenuMode.DRAWER,
      'p-input-filled': this.layoutService.config.inputStyle === InputStyles.FILLED,
      'p-ripple-disabled': !this.layoutService.config.ripple,
      'layout-static-inactive': this.layoutService.state.staticMenuDesktopInactive && this.layoutService.config.menuMode === MenuMode.STATIC,
      'layout-overlay-active': this.layoutService.state.overlayMenuActive,
      'layout-mobile-active': this.layoutService.state.staticMenuMobileActive,
      'layout-topbar-menu-active': this.layoutService.state.topbarMenuActive,
      'layout-menu-profile-active': this.layoutService.state.menuProfileActive,
      'layout-sidebar-active': this.layoutService.state.sidebarActive,
      'layout-sidebar-anchored': this.layoutService.state.anchored,
      'layout-light': this.layoutService.config.colorScheme === ColorScheme.LIGHT,
      'layout-dark': this.layoutService.config.colorScheme === ColorScheme.DARK,
    };

    styleClass['layout-topbar-' + this.layoutService.config.topbarTheme] = true;
    styleClass['layout-menu-' + this.layoutService.config.menuTheme] = true;
    styleClass['layout-menu-profile-' + this.layoutService.config.menuProfilePosition] = true;
    return styleClass;
  }

  private readonly overlayMenuOpenSubscription: Subscription;
  private readonly topbarMenuOpenSubscription: Subscription;
  private readonly menuProfileOpenSubscription: Subscription;
  private menuOutsideClickListener: () => void;
  private menuScrollListener: () => void;
  private topbarMenuOutsideClickListener: () => void;
  private menuProfileOutsideClickListener: () => void;

  public routeUrl: string;

  private readonly logActive: boolean = !1;

  constructor(
    public readonly injector: Injector,
    private readonly menuService: MenuService,
    private readonly renderer: Renderer2,
    // private readonly contexts: ChildrenOutletContexts,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    super(injector);
    this.document.documentElement.lang = this.authenticationService?.currentUser?.value?.userProfile?.langId || 'en-US';
    this.layoutService.config.layoutType = LayoutType.LEGACY;
    this.store.dispatch(this.actions.ChangeLayoutTypeAction({ layoutType: LayoutType.LEGACY }));
    this.layoutService.onConfigUpdate();
    this.logActive && this.logger.debug('layout:', [this.layoutService.config]);

    this.hideMenuProfile();

    this.overlayMenuOpenSubscription = this.layoutService.overlayOpen$.subscribe(() => {
      this.hideTopbarMenu();

      if (!this.menuOutsideClickListener) {
        this.menuOutsideClickListener = this.renderer.listen('document', 'click', (event: ListenEvent): void => {
          const isOutsideClicked = !(this.appSidebar.el.nativeElement.isSameNode(event.target) || this.appSidebar.el.nativeElement.contains(event.target)
            || this.appTopbar.menuButton.nativeElement.isSameNode(event.target) || this.appTopbar.menuButton.nativeElement.contains(event.target));
          if (isOutsideClicked) {
            this.hideMenu();
          }
        });
      }

      if ((this.layoutService.isHorizontal || this.layoutService.isSlim || this.layoutService.isSlimPlus) && !this.menuScrollListener) {
        this.menuScrollListener = this.renderer.listen(this.appSidebar.menuContainer.nativeElement, 'scroll', (_event: ListenEvent): void => {
          if (this.layoutService.isDesktop) {
            this.hideMenu();
          }
        });
      }

      if (this.layoutService.state.staticMenuMobileActive) {
        this.layoutService.blockBodyScroll();
      }
    });

    this.topbarMenuOpenSubscription = this.layoutService.topbarMenuOpen$.subscribe(() => {
      if (!this.topbarMenuOutsideClickListener) {
        this.topbarMenuOutsideClickListener = this.renderer.listen('document', 'click', (event: ListenEvent): void => {
          const isOutsideClicked = !(this.appTopbar.el.nativeElement.isSameNode(event.target) || this.appTopbar.el.nativeElement.contains(event.target)
            || this.appTopbar.mobileMenuButton.nativeElement.isSameNode(event.target) || this.appTopbar.mobileMenuButton.nativeElement.contains(event.target));
          if (isOutsideClicked) {
            this.hideTopbarMenu();
          }
        });
      }

      if (this.layoutService.state.staticMenuMobileActive) {
        this.layoutService.blockBodyScroll();
      }
    });

    this.menuProfileOpenSubscription = this.layoutService.menuProfileOpen$.subscribe(() => {
      this.hideMenu();

      // if (!this.menuProfileOutsideClickListener) {
      //   this.menuProfileOutsideClickListener = this.renderer.listen('document', 'click', (event: ListenEvent): void => {
      //     const isOutsideClicked = !(this.appSidebar.menuProfile.el.nativeElement.isSameNode(event.target) || this.appSidebar.menuProfile.el.nativeElement.contains(event.target));
      //     if (isOutsideClicked) {
      //       this.hideMenuProfile();
      //     }
      //   });
      // }
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: Event | RouterEvent): void => {
        this.hideMenu();
        this.hideTopbarMenu();
        this.hideMenuProfile();
        this.routeUrl = (<RouterEvent>event).url;
      });
  }

  public hideMenu(): void {
    this.layoutService.state.overlayMenuActive = false;
    this.layoutService.state.staticMenuMobileActive = false;
    this.layoutService.state.menuHoverActive = false;
    this.menuService.reset();

    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();
      this.menuOutsideClickListener = null;
    }

    if (this.menuScrollListener) {
      this.menuScrollListener();
      this.menuScrollListener = null;
    }
    this.layoutService.unblockBodyScroll();
  }

  private hideTopbarMenu(): void {
    this.layoutService.state.topbarMenuActive = false;

    if (this.topbarMenuOutsideClickListener) {
      this.topbarMenuOutsideClickListener();
      this.topbarMenuOutsideClickListener = null;
    }
  }

  private hideMenuProfile(): void {
    this.layoutService.state.menuProfileActive = false;

    if (this.menuProfileOutsideClickListener) {
      this.menuProfileOutsideClickListener();
      this.menuProfileOutsideClickListener = null;
    }
  }

  // public getRouteAnimationData(): OutletContext {
  //   return this.contexts.getContext('primary');
  // }

  public ngOnDestroy(): void {
    this.overlayMenuOpenSubscription?.unsubscribe();
    this.topbarMenuOpenSubscription?.unsubscribe();
    this.menuProfileOpenSubscription?.unsubscribe();
    this.menuOutsideClickListener = null;
    this.menuScrollListener = null;
    this.topbarMenuOutsideClickListener = null;
    this.menuProfileOutsideClickListener = null;
  }
}
