// Angular
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
// Rxjs
import { filter } from 'rxjs';
// Caloudi
import BaseComponent from '@base';
// Interface
import { ImgData, LayoutType } from '@base/model';
import { EAAPITYPE, MenuMode, ThemeTopbarColor } from '@core/enum';

@Component({
  selector: 'layout-sidebar',
  templateUrl: './layout-sidebar.component.html',
  styleUrls: ['./layout-sidebar.component.sass'],
  animations: [
    trigger('subMenuInitAnimation', [
      state('notTabMenu', style({ transform: '*' })),
      state('true', style({ transform: 'translate(0, 0)' })),
      state('false', style({ transform: 'translate(0, -100%)' })),
      transition('true <=> false', animate('.4s cubic-bezier(0.86, 0, 0.07, 1)')),
    ]),
  ],
})
export class LayoutSidebarComponent extends BaseComponent implements OnInit, OnDestroy {

  @ViewChild('menuContainer') public readonly menuContainer!: ElementRef<HTMLElement>;
  // @ViewChild(MenuProfileComponent) public readonly menuProfile!: MenuProfileComponent;

  public readonly ThemeTopbarColor: typeof ThemeTopbarColor = ThemeTopbarColor;
  public imgData: ImgData;

  public get isTabMenu(): boolean { return this.layoutService.config.layoutType === LayoutType.TABMENU; }
  public get isDrawer(): boolean { return this.layoutService.config.menuMode === MenuMode.DRAWER; }
  public get isReveal(): boolean { return this.layoutService.config.menuMode === MenuMode.REVEAL; }
  public get menuProfilePosition(): string { return this.layoutService.config.menuProfilePosition; }
  public get logoDisplay(): boolean { return this.layoutService.state.sidebarActive || this.layoutService.state.anchored; }

  private timeout: NodeJS.Timeout = null;

  constructor(
    public readonly injector: Injector,
    public readonly el: ElementRef<HTMLElement>,
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    this.store
      .select(this.selectors.auth)
      .pipe(filter(auth => auth.loggedIn))
      .subscribe(auth => {
        switch (auth.user?.userProfile?.eaapiType) {
          case EAAPITYPE.AWS:
            this.imgData = this.layoutService.IconData().AWS;
            break;
          case EAAPITYPE.GCP:
            this.imgData = this.layoutService.IconData().GCP;
            break;
          case EAAPITYPE.XCloud:
            this.imgData = this.layoutService.IconData().XCLOUD;
            break;
          default:
            this.imgData = this.layoutService.IconData().AZURE;
            break;
        }
      });
  }

  public resetOverlay(): void {
    if (this.layoutService.state.overlayMenuActive) {
      this.layoutService.state.overlayMenuActive = false;
    }
  }

  public onMouseEnter(): void {
    if (!this.layoutService.state.anchored) {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.layoutService.state.sidebarActive = true;
    }
  }

  public onMouseLeave(): void {
    if (!this.layoutService.state.anchored) {
      if (!this.timeout) {
        this.timeout = setTimeout(() => this.layoutService.state.sidebarActive = false, 300);
      }
    }
  }

  public anchor(): void {
    this.layoutService.state.anchored = !this.layoutService.state.anchored;
  }

  public isSVG(payload: ImgData): boolean {
    // this.logger.debug('isSVG:', [payload.path, RegExp(/\.svg$/i).test(payload.path)]);
    return RegExp(/\.svg$/i).test(payload.path);
  }

  public ngOnDestroy(): void {
    this.resetOverlay();
  }
}
