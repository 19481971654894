<a class="layout-config-button topbar_root_menuitem" (click)="onConfigButtonClick($event)">
  <fa-icon [icon]="fas.faCog"></fa-icon>
</a>

<p-sidebar
  [(visible)]="layoutService.state.configSidebarVisible"
  [position]="'right'"
  [appendTo]="'body'"
  [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'">
  <ng-template pTemplate="header">
    <div class="layout-config-header">
      <h5>{{ 'CONFIG.TITLE.THEME_CUSTOMIZATION' | translate | titlecase }}</h5>
    </div>
  </ng-template>

  <p-divider></p-divider>

  <div class="p-3">
    <div class="layout-config-panel d-flex flex-column">
      <div class="layout-config-options">
        <ng-container *ngIf="isSysAdmin">
          <h5>Testing Feats <span>(Admin Only)</span></h5>
          <p-inputSwitch [(ngModel)]="layoutService.specialFeats"></p-inputSwitch>

          <div [@specialFeatAnimation]="layoutService.specialFeats">
            <h5>{{ 'CONFIG.TITLE.LAYOUT_TYPE' | translate | titlecase }}</h5>
            <div class="flex gap-4 layout_type">
              <div class="field-radiobutton flex-1">
                <p-radioButton
                  name="layoutType"
                  inputId="layoutType1"
                  [value]="layoutService.layoutTypes.LEGACY"
                  [disabled]="currentLayoutType === layoutService.layoutTypes.LEGACY"
                  [(ngModel)]="currentLayoutType">
                </p-radioButton>
                <label for="layoutType1">{{ 'CONFIG.TAB_MENU.PRIME_LAYOUT' | translate | titlecase }}</label>
              </div>
              <div class="field-radiobutton flex-1">
                <p-radioButton
                  name="layoutMode"
                  inputId="layoutType2"
                  [value]="layoutService.layoutTypes.TABMENU"
                  [disabled]="currentLayoutType === layoutService.layoutTypes.TABMENU"
                  [(ngModel)]="currentLayoutType">
                </p-radioButton>
                <label for="layoutType2">{{ 'CONFIG.TAB_MENU.TAB_MENU_LAYOUT' | translate | titlecase }}</label>
              </div>
            </div>

            <h5>{{ 'CONFIG.TITLE.SPECIAL_FEATS' | translate | titlecase }}</h5>
            <div class="grid special_feats">
              <div class="col col-fixed">
                <p-inputSwitch
                  id="specialFeat1"
                  [(ngModel)]="layoutService.config.inlineBreadcrumb"
                  (ngModelChange)="onInlineBreadcrumbChange()"></p-inputSwitch>
                <label class="ml-2" for="specialFeat1">
                  {{ 'CONFIG.SPECIAL_FEATS.INLINE_BREADCRUMB' | translate | titlecase }}
                </label>
              </div>
              <div class="col col-fixed">
                <p-inputSwitch
                  id="specialFeat2"
                  [(ngModel)]="layoutService.config.blockLoading"
                  (ngModelChange)="onBlockLoadingChange()"></p-inputSwitch>
                <label class="ml-2" for="specialFeat2">
                  {{ 'CONFIG.SPECIAL_FEATS.BLOCK_LOADING' | translate | titlecase }}
                </label>
              </div>
              <div class="col col-fixed">
                <p-inputSwitch
                  id="specialFeat3"
                  [(ngModel)]="layoutService.config.tabEnrollment"
                  (ngModelChange)="onTabEnrollmentChange()"></p-inputSwitch>
                <label class="ml-2" for="specialFeat3">
                  {{ 'CONFIG.SPECIAL_FEATS.TAB_ENROLLMENT' | translate | titlecase }}
                </label>
              </div>
            </div>
          </div>

          <p-divider></p-divider>
        </ng-container>

        <h5>{{ 'CONFIG.TITLE.SCALE' | translate | titlecase }}</h5>
        <div class="flex align-items-center layout_scale">
          <button
            pButton
            aria-label="Button"
            pRipple
            type="button"
            [faIcon]="fas.faMinus"
            (click)="decrementScale()"
            [text]="true"
            [disabled]="layoutService.config.scale === scales[0]"></button>
          <i
            class="pi pi-circle-on scale-icon"
            *ngFor="let s of scales"
            [ngClass]="{ 'text-primary-500': s === layoutService.config.scale }"
            [pTooltip]="s + ''"
            [tooltipPosition]="'bottom'"
            (click)="applyScale(s)"></i>
          <button
            pButton
            aria-label="Button"
            pRipple
            type="button"
            [faIcon]="fas.faPlus"
            (click)="incrementScale()"
            [text]="true"
            [disabled]="layoutService.config.scale === scales[scales.length - 1]"></button>
        </div>

        <h5>{{ 'CONFIG.TITLE.COLOR_SCHEME' | translate | titlecase }}</h5>
        <div class="flex gap-4 color_scheme">
          <div class="field-radiobutton flex-1">
            <p-radioButton
              name="layoutMode"
              inputId="layoutMode1"
              [value]="layoutService.colorScheme.LIGHT"
              [(ngModel)]="colorScheme"></p-radioButton>
            <label for="layoutMode1">{{ 'CONFIG.LIGHT' | translate | titlecase }}</label>
          </div>
          <div class="field-radiobutton flex-1">
            <p-radioButton
              name="layoutMode"
              inputId="layoutMode2"
              [value]="layoutService.colorScheme.DARK"
              [(ngModel)]="colorScheme"></p-radioButton>
            <label for="layoutMode2">{{ 'CONFIG.DARK' | translate | titlecase }}</label>
          </div>
        </div>

        <ng-container *ngIf="currentLayoutType === layoutService.layoutTypes.LEGACY">
          <ng-container *ngIf="!minimal">
            <h5>{{ 'CONFIG.TITLE.MENU_MODE' | translate | titlecase }}</h5>
            <div class="flex flex-wrap row-gap-3 layout_menu">
              <div class="flex align-items-center gap-2 w-6">
                <p-radioButton
                  name="menuMode"
                  inputId="menuMode1"
                  [value]="layoutService.menuMode.STATIC"
                  [(ngModel)]="menuMode"></p-radioButton>
                <label for="menuMode1">{{ 'CONFIG.STATIC' | translate | titlecase }}</label>
              </div>
              <div class="flex align-items-center gap-2 w-6">
                <p-radioButton
                  name="menuMode"
                  inputId="menuMode2"
                  [value]="layoutService.menuMode.OVERLAY"
                  [(ngModel)]="menuMode"></p-radioButton>
                <label for="menuMode2">{{ 'CONFIG.OVERLAY' | translate | titlecase }}</label>
              </div>
              <div class="flex align-items-center gap-2 w-6">
                <p-radioButton
                  name="menuMode"
                  inputId="menuMode3"
                  [value]="layoutService.menuMode.SLIM"
                  [(ngModel)]="menuMode"></p-radioButton>
                <label for="menuMode3">{{ 'CONFIG.SLIM' | translate | titlecase }}</label>
              </div>
              <div class="flex align-items-center gap-2 w-6">
                <p-radioButton
                  name="menuMode"
                  inputId="menuMode4"
                  [value]="layoutService.menuMode.SLIMPLUS"
                  [(ngModel)]="menuMode"></p-radioButton>
                <label for="menuMode4">{{ 'CONFIG.SLIMPLUS' | translate | titlecase }}</label>
              </div>
              <div class="flex align-items-center gap-2 w-6">
                <p-radioButton
                  name="menuMode"
                  inputId="menuMode5"
                  [value]="layoutService.menuMode.HORIZONTAL"
                  [(ngModel)]="menuMode"></p-radioButton>
                <label for="menuMode5">{{ 'CONFIG.HORIZONTAL' | translate | titlecase }}</label>
              </div>
              <div class="flex align-items-center gap-2 w-6">
                <p-radioButton
                  name="menuMode"
                  inputId="menuMode6"
                  [value]="layoutService.menuMode.REVEAL"
                  [(ngModel)]="menuMode"></p-radioButton>
                <label for="menuMode6">{{ 'CONFIG.REVEAL' | translate | titlecase }}</label>
              </div>
              <div class="flex align-items-center gap-2 w-6">
                <p-radioButton
                  name="menuMode"
                  inputId="menuMode7"
                  [value]="layoutService.menuMode.DRAWER"
                  [(ngModel)]="menuMode"></p-radioButton>
                <label for="menuMode7">{{ 'CONFIG.DRAWER' | translate | titlecase }}</label>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!minimal">
          <h5>{{ 'CONFIG.TITLE.INPUT' | translate | titlecase }}</h5>
          <div class="flex gap-4 layout_input">
            <div class="field-radiobutton flex-1">
              <p-radioButton
                name="inputStyle"
                inputId="inputStyle1"
                [value]="layoutService.inputStyle.OUTLINED"
                [(ngModel)]="inputStyle"></p-radioButton>
              <label for="inputStyle1">{{ 'CONFIG.OUTLINED' | translate | titlecase }}</label>
            </div>
            <div class="field-radiobutton flex-1">
              <p-radioButton
                name="inputStyle"
                inputId="inputStyle2"
                [value]="layoutService.inputStyle.FILLED"
                [(ngModel)]="inputStyle"></p-radioButton>
              <label for="inputStyle2">{{ 'CONFIG.FILLED' | translate | titlecase }}</label>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!minimal">
          <h5>{{ 'CONFIG.THEMES.MENU' | translate | titlecase }}</h5>
          <div class="flex flex-wrap row-gap-3 pb-3">
            <div class="w-3" *ngFor="let t of menuThemes">
              <button
                aria-label="button"
                type="button"
                class="cursor-pointer p-link w-2rem h-2rem border-round shadow-2 flex-shrink-0 flex justify-content-center align-items-center border-circle"
                (click)="onThemeMenuChange(t.label)"
                [pTooltip]="t.label"
                [tooltipPosition]="'top'"
                [ngStyle]="{ 'background-color': t.color }">
                <i
                  *ngIf="layoutService.config.menuTheme === t.label"
                  class="pi pi-check text-lg"
                  [ngClass]="{ 'text-white': layoutService.config.menuTheme !== 'light' }">
                </i>
              </button>
            </div>
          </div>
        </ng-container>

        <h5>{{ 'CONFIG.THEMES.TOPBAR' | translate | titlecase }}</h5>
        <div class="flex flex-wrap row-gap-3 pb-3">
          <div class="w-3" *ngFor="let t of topbarThemes">
            <button
              type="button"
              class="cursor-pointer p-link w-2rem h-2rem border-round shadow-2 flex-shrink-0 flex justify-content-center align-items-center border-circle"
              (click)="onThemeTopbarChange(t.label)"
              [pTooltip]="t.label"
              [tooltipPosition]="'top'"
              [ngStyle]="{ 'background-color': t.color }">
              <i
                *ngIf="layoutService.config.topbarTheme === t.label"
                class="pi pi-check text-lg"
                [ngClass]="{ 'text-white': layoutService.config.topbarTheme !== 'white' }"></i>
            </button>
          </div>
        </div>

        <h5>{{ 'CONFIG.THEMES.COMPONENT' | translate | titlecase }}</h5>
        <div class="flex flex-wrap row-gap-3">
          <div class="w-3" *ngFor="let t of componentThemes">
            <button
              type="button"
              class="cursor-pointer p-link w-2rem h-2rem border-round shadow-2 flex-shrink-0 flex justify-content-center align-items-center border-circle"
              (click)="onThemeComponentChange(t.label)"
              [pTooltip]="t.label"
              [tooltipPosition]="'top'"
              [ngStyle]="{ 'background-color': t.color }">
              <i *ngIf="layoutService.config.componentTheme === t.label" class="pi pi-check text-lg text-white"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-sidebar>
