// Angular
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, Injector, Input, ViewChild } from '@angular/core';
// Rxjs
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
// Caloudi
import { BaseComponent } from '@base';
import { MenuMode } from '@core/enum';
import { UserMenuItem } from '@core/model';

@Component({
  selector: '[prime-submenu]',
  templateUrl: './prime-submenu.component.html',
  styleUrls: ['./prime-submenu.component.sass'],
  animations: [
    trigger('children', [
      state('hiddenAnimated', style({ height: '0px' })),
      state('visibleAnimated', style({ height: '*' })),
      state('visible', style({ height: '*', 'z-index': 100 })),
      state('hidden', style({ height: '0px', 'z-index': '*' })),
      transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
    ]),
  ],
})
export class PrimeSubMenuComponent extends BaseComponent implements AfterViewInit {
  @Input('item') public item: UserMenuItem;
  @Input('root') public root: boolean;
  @Input('visible') public visible: boolean;
  @Input('reset') public get reset(): boolean {
    return this._reset;
  }

  public set reset(val: boolean) {
    this._reset = val;
    if (
      val &&
      (this.layoutService.config.menuMode === MenuMode.HORIZONTAL ||
        this.layoutService.config.menuMode === MenuMode.SLIM)
    ) {
      this.activeIndex = null;
    }
  }

  @Input('parentActive') public get parentActive(): boolean {
    return this._parentActive;
  }

  public set parentActive(val: boolean) {
    this._parentActive = val;
    if (!val) this.activeIndex = null;
  }

  @ViewChild('menu') private readonly menu: ElementRef<HTMLLIElement>;

  private _reset: boolean;
  private _parentActive: boolean;
  private activeIndex: number;
  /** Use Enum For Template */
  public readonly menuMode = MenuMode;

  constructor(public readonly injector: Injector) {
    super(injector);
    fromEvent(window, 'resize')
      .pipe(map(event => <Window>event.target))
      .subscribe(() => this.calcMenuPos());
  }

  public ngAfterViewInit(): void {
    this.calcMenuPos();
  }

  private calcMenuPos(): void {
    const target = this.menu.nativeElement.parentElement.parentElement;
    const list = <HTMLUListElement>target.children[1];

    if (!(target instanceof HTMLLIElement)) return;
    if (target.offsetLeft + list.clientWidth < window.innerWidth) {
      list.style.left = '0';
      list.style.right = '';
    } else {
      list.style.left = 'unset';
      list.style.right = `${target.offsetLeft + target.clientWidth - window.innerWidth}px`;
      console.debug('is target', [[target, list], window.innerWidth]);
    }
  }

  public itemClick(event: Event, item: UserMenuItem, index: number): boolean {
    if (this.root) this.layoutService.state.menuHoverActive = !this.layoutService.state.menuHoverActive;
    // Avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    // Activate current item and deactivate active sibling if any
    this.activeIndex = this.activeIndex === index && this.root ? null : index;

    // Execute command
    if (item.command) item.command({ originalEvent: event, item });

    // Prevent hash change
    if (item.items || (!item.url && !item.routerLink)) {
      // setTimeout(() => this.layoutService.layoutMenuScrollerViewChild?.moveBar(), 450);
      event.preventDefault();
    }

    // Hide menu
    if (!item.items) {
      // this.layoutService.resetMenu = (
      //   this.layoutService.config.colorScheme === MenuMode.HORIZONTAL
      //   || this.layoutService.config.colorScheme === MenuMode.SLIM
      // );

      // this.layoutService.state.overlayMenuActive = false;
      // this.layoutService.state.staticMenuMobileActive = false;
      this.layoutService.state.menuHoverActive = !this.layoutService.state.menuHoverActive;
    }

    return false;
  }

  public checkItemTarget(item: UserMenuItem): void {
    if (item.target === '_blank' && item.routerLink) {
      window.open(item.routerLink, '_blank');
    }
  }

  public onMouseEnter(index: number): void {
    if (
      this.root &&
      this.layoutService.state.menuHoverActive &&
      (this.layoutService.config.menuMode === MenuMode.HORIZONTAL ||
        this.layoutService.config.menuMode === MenuMode.SLIM)
      // && !this.layoutService.isMobile() && !this.layoutService.isTablet()
    ) {
      this.activeIndex = index;
    }
  }

  public isActive(index: number): boolean {
    return this.activeIndex === index;
  }
}
