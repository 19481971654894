<div class="layout-container layout-slim" [ngClass]="containerClass">
  <tabmenu-topbar></tabmenu-topbar>
  <layout-sidebar></layout-sidebar>

  <div class="layout-content-wrapper">
    <spinner *ngIf="layoutService.config?.blockLoading" [ngClass]="{ loading: layoutService.spinnerLoading }"></spinner>

    <!-- <app-breadcrumb></app-breadcrumb> -->
    <ul
      *ngIf="layoutService.breadcrumb && (layoutService.config.inlineBreadcrumb || layoutService.isLaptop)"
      class="main_breadcrumb">
      <li>
        <fa-icon [icon]="fas.faHouse"></fa-icon>
      </li>
      <li *ngFor="let breadcrumbItem of layoutService.breadcrumb.menuItemList; index as i">
        <span *ngIf="i < layoutService.breadcrumb.menuItemList.length">/</span>
        <a *ngIf="breadcrumbItem['routerlink']" [routerLink]="breadcrumbItem['routerlink']">{{
          breadcrumbItem.label
        }}</a>
        <ng-container *ngIf="!breadcrumbItem['routerlink']">{{ breadcrumbItem.label }}</ng-container>
      </li>
    </ul>

    <div class="layout-content">
      <router-outlet></router-outlet>
    </div>

    <!-- <prime-footer></prime-footer> -->
  </div>
  <!-- <prime-config></app-config> -->

  <div *ngIf="layoutService.state.staticMenuMobileActive" class="layout-mask"></div>
</div>
