/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import environment from '@env';
import { BuildName } from '@env/environment.model';
import { AppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

if (module['hot']) {
  module['hot'].accept();
} else {
  switch (environment.name) {
    case BuildName.cmpqa:
      console.trace('HMR disabled');
      break;
    case BuildName.cmpdev:
      console.warn('HMR is not enabled for CMP DEV webpack-dev-server!');
      break;
    case BuildName.ysldev:
      console.warn('HMR is not enabled for YSL DEV webpack-dev-server!');
      break;
    default:
      console.log('HMR enabled');
      break;
  }
}

// process.on('warning', (warning) => {
//   console.warn(warning.stack);
// });
