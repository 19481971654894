// Angular
import { Injectable } from '@angular/core';
// Rxjs
import { of } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
// Caloudi
import { JSONUtil } from '@util';
// Store
import { ChangeLayoutAction, LayoutActionTypes, LayoutPayload } from '@Actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '@Reducers';
import Selectors from '@Selectors';
// Third Party
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class LayoutEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly cookie: CookieService,
    private readonly store: Store<AppState>
  ) { }

  public layoutChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LayoutActionTypes.Change_Layout),
        map((_action: LayoutPayload['Layout_State']) => {
          this.store
            .select(Selectors.layout)
            .pipe(first())
            .subscribe(state => {
              this.cookie.set('caloudi_theme_layout', JSONUtil.stringify(state || {}), void 0, '/');
              // console.debug('layoutChange', [{ ...state }]);
            });
        }),
        catchError((err, cau) => {
          if (err) {
            console.error(err);
            return of(err);
          } else return cau;
        })
      ),
    { dispatch: false }
  );

  public layoutChangeScale$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LayoutActionTypes.Change_Scale),
        map((_action: LayoutPayload['Layout_State']) => ChangeLayoutAction()),
        catchError((err, cau) => {
          if (err) {
            console.error(err);
            return of(err);
          } else return cau;
        })
      ),
    { dispatch: true }
  );

  public layoutChangeInputStyle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LayoutActionTypes.Change_Input_Style),
        map((_action: LayoutPayload['Layout_State']) => ChangeLayoutAction()),
        catchError((err, cau) => {
          if (err) {
            console.error(err);
            return of(err);
          } else return cau;
        })
      ),
    { dispatch: true }
  );

  public layoutChangeColorScheme$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LayoutActionTypes.Change_Color_Scheme),
        map((_action: LayoutPayload['Layout_State']) => ChangeLayoutAction()),
        catchError((err, cau) => {
          if (err) {
            console.error(err);
            return of(err);
          } else return cau;
        })
      ),
    { dispatch: true }
  );

  public layoutChangeOrientationMode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LayoutActionTypes.Change_Menu_Mode),
        map((_action: LayoutPayload['Layout_State']) => ChangeLayoutAction()),
        catchError((err, cau) => {
          if (err) {
            console.error(err);
            return of(err);
          } else return cau;
        })
      ),
    { dispatch: true }
  );

  public layoutChangeMenuColor$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LayoutActionTypes.Change_Menu_Color),
        map((_action: LayoutPayload['Layout_State']) => ChangeLayoutAction()),
        catchError((err, cau) => {
          if (err) {
            console.error(err);
            return of(err);
          } else return cau;
        })
      ),
    { dispatch: true }
  );

  public layoutChangeTopbarColor$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LayoutActionTypes.Change_Topbar_Color),
        map((_action: LayoutPayload['Layout_State']) => ChangeLayoutAction()),
        catchError((err, cau) => {
          if (err) {
            console.error(err);
            return of(err);
          } else return cau;
        })
      ),
    { dispatch: true }
  );

  public layoutChangeComponentColor$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LayoutActionTypes.Change_Component_Color),
        map((_action: LayoutPayload['Layout_State']) => ChangeLayoutAction()),
        catchError((err, cau) => {
          if (err) {
            console.error(err);
            return of(err);
          } else return cau;
        })
      ),
    { dispatch: true }
  );

  public layoutChangeLanguage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LayoutActionTypes.Change_Language),
        map((_action: LayoutPayload['Layout_State']) => ChangeLayoutAction()),
        catchError((err, cau) => {
          if (err) {
            console.error(err);
            return of(err);
          } else return cau;
        })
      ),
    { dispatch: true }
  );

  public layoutChangeRipple$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LayoutActionTypes.Change_Ripple),
        map((_action: LayoutPayload['Layout_State']) => ChangeLayoutAction()),
        catchError((err, cau) => {
          if (err) {
            console.error(err);
            return of(err);
          } else return cau;
        })
      ),
    { dispatch: true }
  );
}
