// Angular
import { Injectable } from '@angular/core';
// Rxjs
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// Store
// import { ChangeLayoutAction, LayoutActionTypes, LayoutPayload, TabMenuActionTypes, TabMenuPayload } from '@Actions';
import { TabMenuActionTypes, TabMenuPayload } from '@Actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
// import { Store } from '@ngrx/store';
// import { AppState } from '@Reducers';
// import Selectors from '@Selectors';
// Third Party
// import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class TabMenuEffects {
  constructor(
    private readonly actions$: Actions // private readonly cookie: CookieService, // private readonly store: Store<AppState>,
  ) { }

  public sideMenuChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TabMenuActionTypes.Change_Tab_Menu_Cache_By_Key),
        map((_action: TabMenuPayload['Change_Tab_Menu_Cache_By_Key']) => {
          // const flatItems = action.cache[0]?.flatMap(val => {
          //   val.items.map(item => ({ label: item.label, route: item.routerLink as string || item.routerlink }));
          // });
        }),
        catchError((err, cau) => {
          if (err) {
            console.error(err);
            return of(err);
          } else return cau;
        })
      ),
    { dispatch: false }
  );
}
