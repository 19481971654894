<div class="layout-topbar">
  <div class="layout-topbar-start">
    <a class="layout_mega_menu"></a>
    <!-- <a pRipple class="layout-topbar-logo h-100" [routerLink]="layoutService.homeLink">
      <img
        class="layout-topbar-logo-full h-100"
        [src]="logoPath(layoutService.config.topbarTheme === ThemeTopbarColor.WHITE ? 'dark' : 'white')"
        alt="caloudi-logo" />
    </a> -->
  </div>

  <div class="layout-topbar-end">
    <div class="layout-topbar-actions-start flex h-100">
      <a class="layout-topbar-logo flex" [routerLink]="layoutService.homeLink">
        <img
          class="layout-topbar-logo-full h-100"
          [src]="logoPath(layoutService.config.topbarTheme === ThemeTopbarColor.WHITE ? 'dark' : 'white')"
          alt="caloudi-logo" />
      </a>

      <tabmenu-tabmenu-core @tabAnimation *ngIf="layoutService.tabMenuItems?.length > 0"></tabmenu-tabmenu-core>

      <ul
        *ngIf="layoutService.breadcrumb && !layoutService.config.inlineBreadcrumb && !layoutService.isLaptop"
        class="layout-topbar-items topbar_breadcrumb flex ml-1">
        <li>
          <fa-icon [icon]="fas.faHouse"></fa-icon>
        </li>
        <li *ngFor="let breadcrumbItem of layoutService.breadcrumb.menuItemList; index as i" @breadcrumbAnimation>
          <span *ngIf="i < layoutService.breadcrumb.menuItemList.length">/</span>
          <a *ngIf="breadcrumbItem['routerlink']" [routerLink]="breadcrumbItem['routerlink']">{{
            breadcrumbItem.label
          }}</a>
          <ng-container *ngIf="!breadcrumbItem['routerlink']">{{ breadcrumbItem.label }}</ng-container>
        </li>
      </ul>
    </div>

    <div class="layout-topbar-actions-end flex h-100" @topbarRightAnimation>
      <div *ngIf="topbarMenuItems?.length <= 0">
        <p class="layout-topbar-item company_id">{{ layoutService.profile?.companyName || '' }}</p>
      </div>

      <div
        menubar
        *ngIf="topbarMenuItems?.length > 0 && layoutService.profile?.privLevel <= 300"
        [items]="topbarMenuItems"
        [root]="true"
        class="layout_topbar_text_items"></div>

      <ul class="layout-topbar-items">
        <li iconmenu></li>
        <li prime-config></li>
      </ul>
    </div>
  </div>
</div>
