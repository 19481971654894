<ul class="layout-menu">
  <ng-container *ngIf="!layoutService.isTabMenu">
    <ng-container *ngFor="let item of model; index as i">
      <li layout-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true" [menuRoot]="true"></li>
      <li *ngIf="item.separator" class="menu-separator"></li>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="layoutService.isTabMenu && layoutService.activeSubMenu">
    <li
      layout-menuitem
      @tabMenuLoadingAnimation
      *ngFor="let item of layoutService.activeSubMenu; index as i"
      [item]="item"
      [index]="i"
      [root]="true"
      [menuRoot]="true"></li>
  </ng-container>
</ul>
