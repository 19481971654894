import { animate, style, transition, trigger } from '@angular/animations';

const subMenuAnimation = trigger('subMenuAnimation', [
  transition(':enter', [
    style({ transform: 'translate(0, 1rem)', opacity: 0 }),
    animate('.4s cubic-bezier(0.86, 0, 0.07, 1)', style({ transform: 'translate(0, 0)', opacity: '*' })),
  ]),
  transition(':leave', [
    animate('.2s cubic-bezier(0.86, 0, 0.07, 1)', style({ transform: 'translate(0, 1rem)', opacity: 0 })),
  ]),
]);

const topbarRightAnimation = trigger('topbarRightAnimation', [
  transition(':enter', [
    style({ transform: 'translateY(-100%)' }),
    animate('.4s cubic-bezier(0.86, 0, 0.07, 1)', style({ transform: 'translateY(0)' })),
  ]),
]);

const breadcrumbAnimation = trigger('breadcrumbAnimation', [
  transition(':enter', [
    style({ opacity: 0, width: 0 }),
    animate(200, style({ opacity: 1, width: '*' })),
  ]),
  transition(':leave', [
    style({ opacity: 1, width: '*' }),
    animate(200, style({ opacity: 0, width: 0 })),
  ]),
]);

const routeAnimation = trigger('routeAnimation', [
  transition('* => *', [
    style({ opacity: 0 }),
    animate('.2s cubic-bezier(0.86, 0, 0.07, 1)', style({ opacity: 1 })),
  ]),
]);

export const layoutAnimations = {
  breadcrumbAnimation,
  routeAnimation,
  subMenuAnimation,
  topbarRightAnimation,
};
