import { Action } from '@ngrx/store';
import { ActionSanitizer, StateSanitizer } from '@ngrx/store-devtools/src/config';
import { ActionTypes } from './actions';
import { AppState } from './reducers';

const logActive: boolean = !1;

export class CustomSanitizer {
  public static ActionSanitizer: ActionSanitizer = (action: Action, id: number): Action => {
    if (action.type !== ActionTypes.blockUI.Count as string && logActive) console.debug(`ActionSanitizer(${id}):`, action.type);
    return action;
  };

  public static StateSanitizer: StateSanitizer = (state: AppState, index: number): AppState => {
    if (state.blockUI?.count <= 0 && logActive) console.debug(`StateSanitizer(${index}):`, [{ ...state }]);
    return state;
  };
}
