// Angular
import { Component, Injector } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, ResolveEnd, RouterEvent } from '@angular/router';
// Primeng
import { MessageService, PrimeNGConfig } from 'primeng/api';
// Rxjs
import { debounceTime, filter, first, forkJoin, of } from 'rxjs';
// Caloudi
import { BaseComponent } from '@base';
import * as trans from '@core/config';
import { UserCoreService } from '@core/service';
import environment from '@env';
import { JSONUtil } from '@util';
// Interface
import { PrevPageVisitData } from '@base/model';
import { MessageSeverity } from '@core/enum';
import { CommonError } from '@core/model';
// Third Party
import axe from 'axe-core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent extends BaseComponent {

  private pageStay: number;

  private readonly logActive: boolean;
  private readonly switchAfterVisible: boolean;

  constructor(
    public readonly injector: Injector,
    private readonly messageService: MessageService,
    private readonly userCoreService: UserCoreService,
    private readonly primengConfig: PrimeNGConfig,
  ) {
    super(injector);

    if (environment.name.match(/^ysl-.*/)) sessionStorage.setItem('hostname', location.hostname);
    else sessionStorage.removeItem('hostname');

    // eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-unsafe-member-access
    const appVer = environment.appVersion !== '@APP_VERSION' ? environment.appVersion : `${require('../../package.json')?.version}`;
    this.cookie.set('version', appVer, void 0, '/');
    this.primengConfig.ripple = true;

    /** register translations */
    this.languageService.loadTranslations(...trans.ORIGIN_TRANSLATION, ...trans.CUSTOM_TRANSLATION);

    /** System Message Management */
    this.store.select(this.selectors.message)
      .pipe(filter(msg => !!msg.severity), debounceTime(10))
      .subscribe(msgState => {
        this.logActive && this.logger.debug('msgState:', [msgState]);
        if (environment.production && msgState.severity === MessageSeverity.ERROR && !msgState.forceProduction) return;
        if (msgState.severity === MessageSeverity.ERROR) this.logActive && this.logger.error('error:', msgState.summary, msgState.message);
        this.messageService.add({
          key: 'appToast',
          severity: msgState.severity,
          summary: msgState.summary,
          detail: ((): string => {
            if (typeof msgState.message === 'string') return msgState.message;
            else {
              const v = msgState.message?.errors ? Object.values(msgState.message?.errors) : msgState.message?.title;
              return ((v?.[0] ?? v) as string) || JSONUtil.stringify(msgState.message);
            }
          })(),
        });
      });

    this.router.events.pipe(filter((e: Event | RouterEvent) => (<RouterEvent>e).url !== '/reload')).subscribe(event => {
      this.logActive && this.logger.debug('router events:', [event]);
      if (event instanceof NavigationStart && this.pageStay) {
        this.pageStay = (Date.now() - this.pageStay) / 1000;
        const prevPageVisitData: PrevPageVisitData = JSONUtil.parse(window.sessionStorage.getItem('prevPageVisitData'));
        this.logActive &&
          this.logger.debug(
            'page routed:',
            [event],
            'page stay:',
            this.pageStay,
            (Date.now() - prevPageVisitData?.pageVisitStartTime) / 1000,
            'prevPageVisitData:',
            [prevPageVisitData]
          );
        this.logActive && this.logger.debug('nav start:', [this.authBreadcrumb, event]);
      } else if (event instanceof ResolveEnd) {
        this.appInsightsService.trackPageView(this.pageStay);
        this.logActive && this.logger.debug('resolve end:', [event, this.routeUtil]);
      } else if (event instanceof NavigationEnd) {
        this.appUtil.localState.subscribe(res => {
          this.logActive && this.logger.debug('state:', [res]);
        });
        this.pageStay = Date.now();
        this.logActive && this.logger.debug('route event', [event, this.routeEvent.value]);

        this.logActive && setTimeout((): void => void axe.run().then(results => {
          // results.violations.forEach(vios => {
          //   vios.nodes.forEach(node => {
          //     const selector = node.target?.[0] as BaseSelector;
          //     if (!selector) return;
          //     const ele = document.querySelector<HTMLElement>(selector);
          //     if (!ele) return;
          //     ele.style.outline = '1px solid red';
          //   });
          // });
          // this.logger.debug(`axe result:`, [results]);
          if (results.violations.length) {
            this.logger.warn('Accessibility issues found', results.violations);
          }
        }).catch((err: CommonError) => {
          this.logger.error('Something bad happened:', err.message);
        }));
      }
    });

    this.routeUtil.onRouteChange.subscribe(event => {
      this.logActive && this.logger.debug('route event:', [{ ...event }]);
    });

    window.onfocus = (e: FocusEvent) =>
      this.checkAlive(e);

    document.onvisibilitychange = (e: globalThis.Event) =>
      document.visibilityState === 'hidden' || this.checkAlive(e);
  }

  public setRipple(e: boolean): void {
    this.primengConfig.ripple = e;
  }

  public static reload(): void {
    console.debug('reload called');
  }

  private checkAlive(event: FocusEvent | globalThis.Event): void {
    this.logActive && this.logger.debug('event:', [event?.type, document.visibilityState]);
    forkJoin([
      of(this.router.url.split('/').filter(res => !!res)),
      this.store.select(this.selectors.getAuth).pipe(first()),
      this.switchAfterVisible ? this.userCoreService.reloadMe() : of(void 0),
    ]).subscribe(([seg, auth, me]) => {
      this.logActive && this.logger.debug('on vis:', [seg, auth, me]);

      // Check alive
      if (!auth.loggedIn || seg[0] !== 'pages') return;
      this.logActive && this.logger.debug('is expired:', [auth.expiresAt, Math.floor(Date.now() / 1000)]);
      this.store.dispatch(this.actions.CheckAliveAction({ expiresAt: auth.expiresAt }));

      // Flush localsotrage
      if (
        !this.switchAfterVisible ||
        me?.currentUser?.userProfile?.eaEnrollmentNumber === auth?.user?.userProfile?.eaEnrollmentNumber
      )
        return;
      this.logActive && this.logger.debug('flush localsotrage:', [seg, auth, me.currentUser.userProfile]);
      this.router.navigateByUrl(me.currentUser.userMenu.homeRouterLink);
      this.store.dispatch(this.actions.UpdateUserAction({ authUser: me.currentUser }));
    });
  }
}
