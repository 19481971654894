// Angular
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Injector, Input } from '@angular/core';
// Rxjs
import { filter, first } from 'rxjs';
// Caloudi
import { BaseComponent } from '@base';
import { MenuService } from '@base/service/layout';
// Interface
import { LayoutType, ThemeOptions } from '@base/model';
import { ColorScheme, InputStyles, LayoutScale, MenuMode, ThemeComponentColor, ThemeMenuColor, ThemeTopbarColor } from '@core/enum';

@Component({
  selector: 'prime-config, [prime-config]',
  templateUrl: './prime-config.component.html',
  styleUrls: ['./prime-config.component.sass'],
  animations: [
    trigger('specialFeatAnimation', [
      state('false', style({ opacity: 0, height: 0 })),
      state('true', style({ opacity: '*', height: '*' })),
      transition('true <=> false', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
    ])
  ]
})
export class PrimeConfigComponent extends BaseComponent {

  @Input('minimal') public minimal: boolean;

  public isSysAdmin: boolean;

  public menuThemes = ThemeOptions.menuTheme;
  public topbarThemes = ThemeOptions.topbarTheme;
  public componentThemes = ThemeOptions.componentTheme;
  public scales = ThemeOptions.scale;

  public onBlockLoadingChange(): void {
    this.store.dispatch(this.actions.ChangeBlockLoadingAction({ blockLoading: this.layoutService.config.blockLoading }));
  }

  public onInlineBreadcrumbChange(): void {
    this.store.dispatch(this.actions.ChangeInlineBreadcrumbAction({ inlineBreadcrumb: this.layoutService.config.inlineBreadcrumb }));
  }

  public onTabEnrollmentChange(): void {
    this.store.dispatch(this.actions.ChangeTabEnrollmentAction({ tabEnrollment: this.layoutService.config.tabEnrollment }));
  }

  public get currentLayoutType(): LayoutType {
    return this.layoutService.config.layoutType;
  }

  public set currentLayoutType(layoutType: LayoutType) {
    this.layoutService.config.layoutType = layoutType;
    this.store.dispatch(this.actions.ChangeLayoutTypeAction({ layoutType }));
    this.layoutService.onConfigUpdate();
  }

  public get menuMode(): MenuMode {
    return this.layoutService.config.menuMode;
  }

  public set menuMode(menuMode: MenuMode) {
    this.logActive && this.logger.debug('onMenuModeChange:', [menuMode]);
    this.layoutService.config.menuMode = menuMode;
    this.store.dispatch(this.actions.ChangeMenuModeAction({ menuMode }));
    this.layoutService.onConfigUpdate();
    this.menuService.reset();
  }

  public get colorScheme(): ColorScheme {
    return this.layoutService.config.colorScheme;
  }

  public set colorScheme(colorScheme: ColorScheme) {
    this.logActive && this.logger.debug('onThemeColorModeChange:', [colorScheme]);
    this.layoutService.onColorSchemeChange(colorScheme);
    // this.layoutService.config.colorScheme = colorScheme;

    if (!this.isInputBackgroundChanged)
      this.layoutService.config.inputStyle = this.layoutService.isDarkMode
        ? InputStyles.FILLED : InputStyles.OUTLINED;

    if (colorScheme === ColorScheme.DARK) {
      this.layoutService.config.menuTheme === ThemeMenuColor.LIGHT
        && (this.layoutService.config.menuTheme = ThemeMenuColor.DARK);
      this.layoutService.config.topbarTheme === ThemeTopbarColor.WHITE
        && (this.layoutService.config.topbarTheme = ThemeTopbarColor.DARK);
    }

    this.store.dispatch(this.actions.ChangeColorSchemeAction({ colorScheme }));
    this.layoutService.onConfigUpdate();
  }

  public get inputStyle(): InputStyles {
    return this.layoutService.config.inputStyle;
  }

  public set inputStyle(input: InputStyles) {
    this.layoutService.config.inputStyle = input;
    this.isInputBackgroundChanged = true;
    this.store.dispatch(this.actions.ChangeInputStyleAction({ input }));
    this.layoutService.onConfigUpdate();
  }

  private isInputBackgroundChanged: boolean;

  private readonly logActive: boolean = !1;

  constructor(
    public readonly injector: Injector,
    private readonly menuService: MenuService,
  ) {
    super(injector);

    this.authenticationService.currentUser
      .pipe(first(), filter(user => !!user?.userProfile))
      .subscribe(({ userProfile: { appRoles } }) => {
        this.logActive && this.logger.debug('appRoles:', [appRoles]);
        this.isSysAdmin = appRoles.some(role => role.toLowerCase() === 'sysadmin');
      });
  }

  public decrementScale(): void {
    if (this.layoutService.config.scale <= this.scales[0]) return;
    this.layoutService.selectedScale--;
    this.applyScale(this.scales[this.layoutService.selectedScale]);
  }

  public incrementScale(): void {
    if (this.layoutService.config.scale >= this.scales[this.scales.length - 1]) return;
    this.layoutService.selectedScale++;
    this.applyScale(this.scales[this.layoutService.selectedScale]);
  }

  public applyScale(scale: typeof LayoutScale): void {
    this.logger.debug('applyScale:', [scale, this.layoutService.selectedScale]);
    this.layoutService.config.scale = scale;
    document.documentElement.style.fontSize = String(scale) + 'px';
    this.store.dispatch(this.actions.ChangeScaleAction({ scale }));
  }

  public onThemeMenuChange(menuTheme: ThemeMenuColor): void {
    this.logActive && this.logger.debug('onThemeMenuChange:', [menuTheme]);
    this.layoutService.config.menuTheme = menuTheme;
    this.store.dispatch(this.actions.ChangeMenuColorAction({ menuTheme }));
    this.layoutService.onConfigUpdate();
  }

  public onThemeTopbarChange(topbarColor: ThemeTopbarColor): void {
    this.logActive && this.logger.debug('onThemeTopbarChange:', [topbarColor]);
    this.layoutService.config.topbarTheme = topbarColor;
    this.store.dispatch(this.actions.ChangeTopbarColorAction({ topbarColor }));
    this.layoutService.onConfigUpdate();
  }

  public onThemeComponentChange(componentColor: ThemeComponentColor): void {
    this.logActive && this.logger.debug('onThemeComponentChange:', [componentColor]);
    this.layoutService.config.componentTheme = componentColor;
    this.store.dispatch(this.actions.ChangeComponentColorAction({ componentColor }));
    this.layoutService.onConfigUpdate();
  }

  // public onRippleChange(event: MouseEvent): void {
  //   this.logActive && this.logger.debug('onRippleChange:', [event]);
  //   this.layoutService.config.ripple = !this.layoutService.config.ripple;
  //   this.store.dispatch(this.actions.ChangeRippleAction({ ripple: this.layoutService.config.ripple }));
  //   this.layoutService.onConfigUpdate();
  // }

  // public onLayoutTypeChange(layoutType: LayoutType): void {
  //   this.logActive && this.logger.debug('onLayoutTypeChange:', [layoutType]);
  //   this.layoutService.config.layoutType = layoutType;
  //   this.store.dispatch(this.actions.ChangeLayoutTypeAction({ layoutType }));
  //   this.layoutService.onConfigUpdate();
  // }

  public onConfigButtonClick(event: MouseEvent): void {
    this.layoutService.showConfigSidebar();
    event.preventDefault();
  }
}
