<!-- <div *ngIf="item.items"
         class="tab_menu_item tab_menu_dropdown">
      <p-dropdown scrollHeight="400px"
                  [placeholder]="'LABEL.UNSELECTED' | translate"
                  [options]="item.items"
                  [optionLabel]="'label'"
                  [appendTo]="'body'"
                  [(ngModel)]="selectedDropdownTab"
                  (ngModelChange)="onSelectDropdownTab($event)">
      </p-dropdown>
    </div> -->

<ng-container *ngIf="tabMenuItem.items">
  <span [faIcon]="fas.faEllipsis"></span>
  <ng-container *ngIf="tabMenuItem.items && displaySublist">
    <section #tabMenuItemList @subMenuAnimation class="tab_menu_item_list" [style]="{ '--offset-x': offsetX }">
      <a *ngFor="let item of tabMenuItem.items" (click)="subListClicked(item)">
        <ng-template [ngTemplateOutlet]="tabItem" [ngTemplateOutletContext]="{ item }"></ng-template>
      </a>
    </section>
  </ng-container>
</ng-container>

<ng-container *ngIf="!tabMenuItem.items">
  <ng-template [ngTemplateOutlet]="tabItem" [ngTemplateOutletContext]="{ item: tabMenuItem }"></ng-template>
</ng-container>

<ng-template #tabItem let-item="item">
  <span
    *ngIf="!item.iconData && iconVer(item.icon) !== 'pi'"
    class="fa_icon"
    [faIcon]="fab[faIconConvert(item.icon)] || fas[faIconConvert(item.icon)]"></span>
  <img
    *ngIf="item.iconData && !isSVG(item.iconData)"
    [src]="item.iconData.path"
    [alt]="item.iconData.alt"
    [title]="item.iconData.title" />
  <svg *ngIf="item.iconData && isSVG(item.iconData)">
    <use [attr.href]="item.iconData.path + '#logo'"></use>
  </svg>
  <span class="tab_menu_item_label">{{ item.label }}</span>
</ng-template>
