// Angular
import { Routes } from '@angular/router';
import { AuthGuard } from '@core/guard';
// Caloudi
import { LayoutWrapperComponent } from '@base/component/layout-wrapper/layout-wrapper.component';
import { HomeComponent } from '@home';
import { RoutesIndex } from './routes.index';

// First Level Route
export const APP_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import('@unauthurized/unauthurized.module').then(m => m.UnauthurizedModule),
  },
  {
    path: 'pages',
    canActivate: [AuthGuard],
    component: LayoutWrapperComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: HomeComponent,
        data: { redirectTo: '', path: 'APP_ROUTES_PAGES' },
      },
      {
        path: 'cmp',
        loadChildren: () => import('./cmp-common/cmp-common.module').then(m => m.CMPCommonModule),
      },
      ...RoutesIndex,
    ],
  },
  {
    path: '**',
    component: HomeComponent,
    data: { redirectTo: '', path: 'APP_ROUTES' },
  },
];
