// Angular
import { Component, Injector, ViewEncapsulation } from '@angular/core';
// Caloudi
import { BaseComponent } from '@base';
// Interface
import { ColorScheme } from '@core/enum';

@Component({
  selector: 'tabmenu-tabmenu-core',
  templateUrl: './tabmenu-core.component.html',
  styleUrls: ['./tabmenu-core.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class TabMenuTabMenuCoreComponent extends BaseComponent {
  public colorScheme: typeof ColorScheme = ColorScheme;

  constructor(public readonly injector: Injector) {
    super(injector);
  }
}
