// Angular
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Rxjs
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
// Store
import * as StoreActions from '@Actions';
import { AuthActions, AuthActionTypes, AuthPayload, LogoutAction, TabMenuStaticActions } from '@Actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
// Caloudi
import { AppComponent } from '@app';
import { UserCoreService } from '@core/service';
import { AppUtilModule, RouteUtilModule } from '@core/service/common';
import { JSONUtil, MenuUtil } from '@util';
// Interface
import { LayoutType } from '@base/model';
import { EAAPITYPE } from '@core/enum';
import { ReturnUrlItem } from '@core/model';

@Injectable()
export class AuthEffects {
  private readonly returnUrl: string;

  constructor(
    private readonly store: Store<AppState>,
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly userCoreService: UserCoreService,
    private readonly route: ActivatedRoute,
    private readonly routeUtil: RouteUtilModule,
    private readonly appUtil: AppUtilModule
  ) {
    this.returnUrl = (this.route.snapshot.queryParams?.returnUrl as string) || '/login';
  }

  public login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.Login),
        map(({ authUser }: AuthPayload['Login']) => {
          let returnUrl: string;
          let loginReturnUrl: string;
          try {
            returnUrl = JSONUtil.parse<ReturnUrlItem>(sessionStorage.getItem('returnUrl'))?.[this.appUtil.userId];
            loginReturnUrl = sessionStorage.getItem('loginReturnUrl');
          } catch (error) {
            returnUrl = undefined;
          }

          this.store.dispatch(
            StoreActions.AuthActions.LoadBreadcrumbsAction({
              breadcrumbs: MenuUtil.constructBreadcrumb(MenuUtil.getAllMenuItemsFromUserMenu(authUser.userMenu)),
            })
          );

          if (authUser.ssoRouterLink) {
            this.router.navigateByUrl(authUser.ssoRouterLink);
            return void 0;
          }

          if (typeof loginReturnUrl === 'string' && loginReturnUrl.length > 0) {
            this.router.navigateByUrl(loginReturnUrl);
            sessionStorage.removeItem('loginReturnUrl');
            return void 0;
          }

          if (typeof returnUrl === 'string' && returnUrl.length > 0) {
            this.router.navigateByUrl(returnUrl);
            const rest: ReturnUrlItem = JSONUtil.parse<ReturnUrlItem>(sessionStorage.getItem('returnUrl'));
            delete rest?.[this.appUtil.userId];
            Object.keys(rest).length > 0
              ? sessionStorage.setItem('returnUrl', JSONUtil.stringify(rest))
              : sessionStorage.removeItem('returnUrl');
            return void 0;
          }

          this.router.navigate([authUser.userMenu?.homeRouterLink || this.returnUrl]);
        }),
        catchError((err, cau) => {
          if (err) {
            console.error(err);
            return of(err);
          } else return cau;
        })
      ),
    { dispatch: false }
  );

  public autoLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.Auto_Login),
        concatMap(({ authUser }: AuthPayload['Auto_Login']) =>
          // console.debug('autoLogin', [action]);
          [
            StoreActions.LoadBreadcrumbsAction({
              breadcrumbs: MenuUtil.constructBreadcrumb(MenuUtil.getAllMenuItemsFromUserMenu(authUser.userMenu)),
            }),
          ]),
        catchError((err, cau) => {
          if (err) {
            console.error(err);
            return of(err);
          } else return cau;
        })
      ),
    { dispatch: true }
  );

  public logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.Logout),
        map(() => {
          location.href = '/login';
        }),
        catchError((err, cau) => {
          if (err) {
            console.error(err);
            return of(err);
          } else return cau;
        })
      ),
    { dispatch: false }
  );

  public tokenExpired$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.Token_Expired),
        map(({ authUser }: AuthPayload['Token_Expired']) => {
          if (authUser) sessionStorage.setItem(
            'returnUrl',
            JSONUtil.stringify({
              [authUser.userProfile.userId]: this.routeUtil.fullUrl || location.href.split(location.origin)[1],
            })
          );
          return LogoutAction();
        }),
        catchError((err, cau) => {
          if (err) {
            console.error(err);
            return of(err);
          } else return cau;
        })
      ),
    { dispatch: true }
  );

  public updateUserProfile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.Update_User),
        concatMap(({ authUser }: AuthPayload['Update_User']) => {
          const breadcrumbs = MenuUtil.constructBreadcrumb(MenuUtil.getAllMenuItemsFromUserMenu(authUser.userMenu));
          const dispatch =
            authUser.userProfile?.eaapiType === EAAPITYPE.XCloud
              ? [TabMenuStaticActions.ChangeLayoutTypeAction({ layoutType: LayoutType.TABMENU })]
              : [];
          return [
            ...dispatch,
            StoreActions.LoadBreadcrumbsAction({
              breadcrumbs: breadcrumbs,
            }),
            StoreActions.SetBreadcrumbAction({
              breadcrumb: MenuUtil.getBreadcrumb(breadcrumbs, this.router.url),
            }),
          ];
        }),
        catchError((err, cau) => {
          if (err) {
            console.error(err);
            return of(err);
          } else return cau;
        })
      ),
    { dispatch: true }
  );

  public refreshUserProfile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.Refresh_User),
        map(() => {
          this.userCoreService.updateUserLang(document.documentElement.lang).subscribe(res => {
            this.store.dispatch(StoreActions.UpdateUserAction({ authUser: res.currentUser }));
          });
        }),
        catchError((err, cau) => {
          if (err) {
            console.error(err);
            return of(err);
          } else return cau;
        })
      ),
    { dispatch: false }
  );

  public refreshPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.Refresh_Page),
        map(() => {
          // console.debug('refresh page:', [this.router.url, this.router.routerState.snapshot.url]);
          const url = this.router.url;
          this.router.navigateByUrl('/reload', { skipLocationChange: true }).then(() => this.router.navigateByUrl(url));
          AppComponent.reload();
        }),
        catchError((err, cau) => {
          if (err) {
            console.error(err);
            return of(err);
          } else return cau;
        })
      ),
    { dispatch: false }
  );

  public checkAlive$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.Check_Alive),
        concatMap(({ expiresAt }: AuthPayload['Check_Alive']) =>
          [expiresAt - Math.floor(Date.now() / 1000) < 0 ? LogoutAction() : AuthActions.CheckAliveIdleAction()]),
        catchError((err, cau) => {
          if (err || err instanceof Error) {
            console.error(err);
            return of(err);
          } else return cau;
        })
      ),
    { dispatch: true }
  );

  public checkAliveIdle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.Check_Alive_Idle),
        map(() => {
          // console.debug(`checkAliveIdle`);
          return void 0;
        }),
        catchError((err, cau) => {
          if (err || err instanceof Error) {
            console.error(err);
            return of(err);
          } else return cau;
        })
      ),
    { dispatch: false }
  );
}
