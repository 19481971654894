<div class="layout-container" [ngClass]="containerClass">
  <prime-topbar></prime-topbar>
  <!-- <prime-rightmenu></prime-rightmenu> -->
  <layout-sidebar></layout-sidebar>

  <div class="layout-content-wrapper">
    <spinner *ngIf="layoutService.config?.blockLoading" [ngClass]="{ loading: layoutService.spinnerLoading }"></spinner>
    <!-- <app-breadcrumb></app-breadcrumb> -->

    <div [@routeAnimation]="routeUrl" class="layout-content">
      <router-outlet></router-outlet>
    </div>

    <!-- <prime-footer></prime-footer> -->
  </div>
  <!-- <prime-config></prime-config> -->

  <div *ngIf="layoutService.state.staticMenuMobileActive" class="layout-mask"></div>
</div>
