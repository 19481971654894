<div class="layout-sidebar" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
  <div class="layout-sidebar-top">
    <button aria-label="button" class="layout-sidebar-anchor p-link" type="button" (click)="anchor()"></button>
    <img
      *ngIf="logoDisplay && (isDrawer || isReveal) && !isTabMenu"
      class="sidebar_logo"
      [src]="logoPath(layoutService.config.topbarTheme === ThemeTopbarColor.WHITE ? 'dark' : 'white')"
      alt="caloudi-logo" />

    <div *ngIf="!logoDisplay && eaAPIType && (isDrawer || isReveal || isTabMenu)" class="cloud_logo">
      <svg *ngIf="imgData && isSVG(imgData)">
        <use [attr.href]="imgData.path + '#logo'"></use>
      </svg>
    </div>
  </div>
  <!-- <app-menu-profile #menuProfileStart *ngIf="menuProfilePosition === 'start'"></app-menu-profile> -->
  <!-- <div #menuContainer *ngIf="!layoutService.isTabMenu" class="layout-menu-container">
    <layout-menu></layout-menu>
  </div> -->

  <!-- <code>tab menu: {{ isTabMenu }}</code> -->

  <div
    #menuContainer
    [ngClass]="{ 'layout-menu-container': !isTabMenu }"
    [@subMenuInitAnimation]="!isTabMenu ? 'notTabMenu' : layoutService.activeSubMenu?.length > 0">
    <layout-menu></layout-menu>
  </div>

  <!-- <app-menu-profile #menuProfileEnd *ngIf="menuProfilePosition === 'end'"></app-menu-profile> -->
</div>
