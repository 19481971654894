// Angular
import { Component, ElementRef, Injector, Input } from '@angular/core';
// Caloudi
import { BaseComponent } from '@base';
import { MenuUtil } from '@util';
// Interface
import { layoutAnimations } from '@base/component/layout-shared';
import { UserMenuItem } from '@core/model';

@Component({
  selector: 'menubar, [menubar]',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.sass'],
  animations: [layoutAnimations.subMenuAnimation],
})
export class MenubarComponent extends BaseComponent {
  public readonly iconVer: typeof MenuUtil.iconVer = e => MenuUtil.iconVer(e);
  public readonly faIconConvert: typeof MenuUtil.faIconConvert = e => MenuUtil.faIconConvert(e);

  @Input('items') public menuItems: UserMenuItem[];
  @Input('root') public root: boolean;

  public mouseOver: string;
  public menuWidth: string;

  private readonly logActive: boolean = !1;

  constructor(public readonly injector: Injector, public readonly el: ElementRef<HTMLElement>) {
    super(injector);
  }

  public anchor(anchor: string, el?: HTMLElement): void {
    if (anchor) this.logActive && this.logger.debug('anchor:', [anchor, el]);
    this.mouseOver = anchor;
    if (!el) return void (this.menuWidth = 'unset');
    setTimeout(() => {
      const ele = el?.querySelector('section.menu_section')?.querySelectorAll('a');
      if (!ele) return;
      const bound = ele[0].getBoundingClientRect();
      this.menuWidth = (ele.length > 16 ? bound.width * 2 : bound.width) + 'px';
      this.logActive && this.logger.debug('widths:', [this.menuItems, el, ele, bound, this.menuWidth]);
    });
  }

  public navigateTo(routeLink: string): void {
    if (!routeLink) return;
    this.router.navigate([routeLink]);
  }
}
