// Angular
import { NgModule } from '@angular/core';
// Primeng
import { PrimengModule } from '@primeng';
// Caloudi
import * as Component from '.';
import { LayoutSharedModule } from '../layout-shared/layout-shared.module';

const FEATURE_COMPONENTS = [
  Component.PrimeMainComponent,
  Component.PrimeSubMenuComponent,
  Component.PrimeTopbarComponent,
];

@NgModule({
  imports: [PrimengModule, LayoutSharedModule],
  declarations: [FEATURE_COMPONENTS],
  exports: [Component.PrimeMainComponent],
})
export class LayoutModule { }
