<div class="layout-topbar">
  <div class="layout-topbar-start">
    <a pRipple class="layout-topbar-logo h-100" [ngClass]="{ 'd-none': isSlim }" [routerLink]="homeLink">
      <img
        class="layout-topbar-logo-full w-100"
        [src]="logoPath(layoutService.config.topbarTheme === ThemeTopbarColor.WHITE ? 'dark' : 'white')"
        alt="caloudi-logo" />
    </a>

    <div
      *ngIf="eaAPIType"
      class="cloud_logo"
      [ngClass]="{ 'w-100': isSlim, button_padding: cloudLogo, iconless: !imgData }">
      <svg *ngIf="imgData && isSVG(imgData)">
        <use [attr.href]="imgData.path + '#logo'"></use>
      </svg>
    </div>

    <a #menuButton class="layout-menu-button" (click)="onMenuButtonClick()" pRipple>
      <i class="pi pi-chevron-right"></i>
    </a>

    <a #mobileMenuButton class="layout-topbar-mobile-button" (click)="onMobileTopbarMenuButtonClick()" pRipple>
      <i class="pi pi-ellipsis-v"></i>
    </a>
  </div>

  <div class="layout-topbar-end">
    <div class="layout-topbar-actions-start flex h-100">
      <!-- <div class="cloud_logo h-100" [ngClass]="cloudLogo" *ngIf="eaAPIType">
        <svg *ngIf="imgData && isSVG(imgData)">
          <use [attr.href]="imgData.path + '#logo'"></use>
        </svg>
      </div> -->

      <!-- <p-breadcrumb
        *ngIf="breadcrumb?.menuItemList"
        [model]="breadcrumb?.menuItemList"
        [styleClass]="'border-0 bg-transparent flex h-100'"
        [home]="{ routerLink: homeLink }"></p-breadcrumb> -->

      <ul class="layout-topbar-items topbar_breadcrumb" *ngIf="breadcrumb">
        <li class="layout-topbar-item">
          <fa-icon [icon]="fas.faHouse"></fa-icon>
        </li>

        <li *ngFor="let breadcrumbItem of breadcrumb.menuItemList; index as i" @breadcrumbAnimation>
          <span *ngIf="i < breadcrumb.menuItemList.length">/</span>
          <a *ngIf="breadcrumbItem['routerlink']" [routerLink]="breadcrumbItem['routerlink']">{{
            breadcrumbItem.label
          }}</a>
          <ng-container *ngIf="!breadcrumbItem['routerlink']">{{ breadcrumbItem.label }}</ng-container>
        </li>
      </ul>
    </div>

    <div class="layout-topbar-actions-end flex h-100" @topbarRightAnimation>
      <!-- <p-menubar
        *ngIf="topbarMenuItems?.length > 0 && layoutService.profile?.privLevel <= 300"
        [model]="topbarMenuItems"></p-menubar> -->

      <div
        menubar
        *ngIf="topbarMenuItems?.length <= 0"
        [items]="bindCompanyNameOnly"
        [root]="true"
        class="layout_topbar_text_items"></div>
      <div
        menubar
        *ngIf="topbarMenuItems?.length > 0 && layoutService.profile?.privLevel <= 300"
        [items]="topbarMenuItems"
        [root]="true"
        class="layout_topbar_text_items"></div>

      <ul class="layout-topbar-items">
        <!-- <li *ngFor="let item of topbarMenuItems"> -->
        <li iconmenu></li>
        <li prime-config></li>
      </ul>
    </div>
  </div>
</div>
