// Angular
import { Injectable } from '@angular/core';
// Caloudi
import { LOCALE_KEYS, TRANSLATION_INDEX } from '@core/config';

@Injectable({ providedIn: 'root' })
export class LocaleService {
  // Locale Keys
  public get localeKeys(): typeof LOCALE_KEYS {
    return LOCALE_KEYS;
  }

  public get TranslationIndex(): typeof TRANSLATION_INDEX {
    return TRANSLATION_INDEX;
  }
}
