/* eslint-disable @typescript-eslint/no-explicit-any */
// Angular
import { Location } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Event, ResolveStart, Router, RouterEvent } from '@angular/router';
// Rxjs
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, pairwise, takeUntil } from 'rxjs/operators';
// NGX
import { NGXLogger } from 'ngx-logger';
// Caloudi
import { AuthenticationService } from '@core/service/config';
import environment from '@env';
import { JSONUtil } from '@util';
import { AuthService, UserCoreService } from '../service/api';
import { DisconnectService, ExcludeListService } from '../service/http';
// Store
import Actions from '@Actions';
import { Store } from '@ngrx/store';
import { AppState } from '@Reducers';
// Service
import { MessageSeverity } from '@core/enum';
import { AuthUser, CommonHttpErrorResponse } from '@core/model';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  /** Token refresh control */
  private isRefreshing: boolean;
  private requestCount = 0;

  private readonly cookie: CookieService;
  private readonly logActive = !1;

  constructor(
    protected readonly _injector: Injector,
    private readonly logger: NGXLogger,
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService,
    private readonly authService: AuthService,
    private readonly location: Location,
    private readonly userService: UserCoreService,
    // Private componentStateService: ComponentStateService,
    private readonly disconnectService: DisconnectService,
    private readonly excludeListService: ExcludeListService
  ) {
    this.cookie = _injector.get(CookieService);

    this.router.events.pipe(filter(event => event instanceof ResolveStart)).subscribe((event: Event | RouterEvent) => {
      this.logActive && this.logger.debug('ResolveStart:', [event, this.excludeListService.showList()]);

      // If url in this list will ignore disconnect
      if (this.excludeListService.getResult((<RouterEvent>event).url)) return;

      this.disconnectService.cancelPendingRequests();
      this.requestCount = 0;
    });
  }

  public intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    const path = this.location.path().toLowerCase();
    const excludePath = this.excludeListService.getUnblockPath();
    const excludeList = this.excludeListService.getUnblockList().map(path => path.toLowerCase());

    // Logs
    this.logActive && this.logger.debug('intercept:', [request, next]);
    this.logActive && this.logger.debug('path:', [path, path.indexOf('encusertoken')]);

    excludeList.some(p => p === path) ? (this.requestCount = 0) : (this.requestCount += 1);
    if (!excludeList.some(p => p === path))
      this.store.dispatch(Actions.BlockUICountAction({ count: this.requestCount }));
    this.logActive && this.logger.debug('blockUIService:', [this.requestCount]);

    // Add slash header with jwt token if available
    const currentUser = this.authenticationService.currentUser.value;
    if (currentUser?.accessToken && !excludePath.some(key => path.startsWith(key))) {
      let { expireDuration, refreshDuration } = this.authenticationService.getTokenDuration();
      if (expireDuration < 0) {
        // this.blockUIService.reset(BLOCKUI_DEFAULT);
        // this.blockUIService.stop(BLOCKUI_DEFAULT);
        this.store.dispatch(Actions.LogoutAction());
        return EMPTY;
      }
      if (!request.headers.has('Authorization')) {
        request = this.addToken(request, currentUser.accessToken);
      }

      // set api-type header
      request = this.addAPITypeHeader(request, path);

      // Refrech access token if it will be expired soon
      if (refreshDuration < 0 && !this.isRefreshing) {
        this.isRefreshing = true;
        this.authService
          .refreshAccessToken()
          .pipe(
            catchError((error: string) => {
              this.store.dispatch(Actions.LogoutAction());
              this.isRefreshing = false;
              return throwError(() => new Error(error));
            })
          )
          .subscribe(accessToken => {
            this.store.dispatch(Actions.RefreshTokenAction({ accessToken: accessToken }));
            this.isRefreshing = false;
          });
      }
    } else if (path.startsWith('/pages') && !path.includes('encusertoken')) {
      // User is not authenticated and current location starts with /pages
      // TDOD: parallel requests cause blockUI blocks the screen
      this.logActive && this.logger.debug('user is not authenticated and current location starts with /pages');
      // this.blockUIService.reset(BLOCKUI_DEFAULT);
      // this.blockUIService.stop(BLOCKUI_DEFAULT);
      return EMPTY;
    }

    return next.handle(request).pipe(
      pairwise(),
      map((events: HandleResponsePair) => {
        this.logActive && this.logger.debug('handle:', events);
        return events[1];
      }),
      takeUntil(this.disconnectService.onCancelPendingRequests()),
      catchError((err, cau) => err ? of(err) : cau),
      map((event: HandleResponseEvent) => {
        this.logActive && this.logger.debug('HandleResponseEvent:', [event]);

        // Handle BlockUI
        event instanceof HttpErrorResponse ? (this.requestCount = 0) : (this.requestCount -= 1);

        // Error handling
        if (event instanceof HttpErrorResponse) {
          // Ignore login error. leave it to login component for error handling
          // if (event.url.endsWith('api/auth/login')) return void 0;
          // Ignore token expire error. leave it to jwt interceptor for error handling
          if (event.status === 401 && event.headers.has('www-authenticate')) return void 0;

          this.logger.error(`${event.name} (${new Date().toISOString()})`, { request, event, path, currentUser });

          this.store.dispatch(Actions.GlobalMsgAction({
            forceProduction: true,
            severity: MessageSeverity.ERROR,
            summary: event.error?.message || event.statusText || event.message,
            message: (event.error?.message || event.message)?.split(': ')?.[0],
            msgDate: new Date().toISOString(),
          }));
        }

        if (event.status !== 200)
          this.logActive && this.logger.debug('error handle:', { request, event, path, currentUser });

        if (event.status === 204)
          this.logActive && this.logger.debug('success but no response', { event });

        this.store.dispatch(
          this.requestCount > 0
            ? Actions.BlockUICountAction({ count: this.requestCount })
            : Actions.BlockUIClearAction()
        );

        if (event instanceof HttpErrorResponse) return void 0;
        if (event.headers.get('ng-version-control')) this.handleVersionControl(event, currentUser);
        return event;
      })
    );
  }

  private handleVersionControl<T>(event: HttpResponse<T>, currentUser: AuthUser): void {
    localStorage.removeItem('ngVersionControl');
    const bnENV: string = environment.buildNumber;
    const bnLS: string = this.cookie.get('ngVersionControl') || '';
    const bnSV: string = JSONUtil.parse<VersionControl>(event.headers.get('ng-version-control')).buildNumber;
    this.logActive && this.logger.debug('buildNumber:', [bnENV, bnLS, bnSV]);

    if (Number(bnENV) >= Number(bnSV) || Number(bnLS) >= Number(bnSV)) return;
    this.cookie.set('ngVersionControl', bnSV, void 0, '/');

    if (!currentUser?.accessToken) location.reload();
    else
      this.userService.reloadMe().subscribe(({ currentUser }) => {
        this.store.dispatch(Actions.UpdateUserAction({ authUser: currentUser }));
        location.reload();
      });
  }

  private addToken<T>(request: HttpRequest<T>, token: string): HttpRequest<T> {
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }

  private addAPITypeHeader<T>(request: HttpRequest<T>, path: string): HttpRequest<T> {
    if (path.includes('azure')) return request.clone({ setHeaders: { 'api-type': 'Azure' } });
    else if (path.includes('gcp')) return request.clone({ setHeaders: { 'api-type': 'GCP' } });
    else if (path.includes('aws')) return request.clone({ setHeaders: { 'api-type': 'AWS' } });
    else if (path.includes('xcloud')) return request.clone({ setHeaders: { 'api-type': 'XCloud' } });
    else if (path.includes('microsoft-365')) return request.clone({ setHeaders: { 'api-type': 'M365' } });
    else if (path.includes('cloud-sec')) return request.clone({ setHeaders: { 'api-type': 'CloudSec' } });
    else if (path.includes('vrm')) return request.clone({ setHeaders: { 'api-type': 'VRM' } });
    else return request;
  }
}

type HandleResponsePair<T = any> = [{ type: number; }, HttpResponse<T>];
type HandleResponseEvent<T = any> = CommonHttpErrorResponse | HttpResponse<T>;
type VersionControl = { buildNumber: string; };
